import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IElxData } from '../../types'

interface IDateTriggerTypeState {
  dateTriggerType: IElxData | null
}

const initialState: IDateTriggerTypeState = {
  dateTriggerType: null,
}

export const dateTriggerTypeSlice = createSlice({
  initialState,
  name: 'dateTriggerTypeSlice',
  reducers: {
    setDateTriggerType: (state, action: PayloadAction<IElxData>) => {
      state.dateTriggerType = action.payload
    },
  },
})

export default dateTriggerTypeSlice.reducer

export const { setDateTriggerType } = dateTriggerTypeSlice.actions
