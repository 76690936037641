import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISurveyMonkeyMaster } from '../../types'

interface ISurveyMonkeyState {
  masters: ISurveyMonkeyMaster[] | null
}

const initialState: ISurveyMonkeyState = {
  masters: null,
}

export const surveyMonkeySlice = createSlice({
  initialState,
  name: 'surveyMonkeySlice',
  reducers: {
    setSurveyMonkeyMaster: (state, action: PayloadAction<ISurveyMonkeyMaster[]>) => {
      state.masters = action.payload
    },
  },
})

export default surveyMonkeySlice.reducer

export const { setSurveyMonkeyMaster } = surveyMonkeySlice.actions
