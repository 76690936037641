/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  Grid,
  FormControlLabel,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import {
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
} from '../../redux/api/applicationApi'
import { IApplication } from '../../types'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}

interface Props {
  application?: IApplication
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  application: yup.string().required('Application is required'),
  homeUrl: yup.string().required('Home URL is required'),
  logoImg: yup.mixed().notRequired(),
  bgImg: yup.mixed().notRequired(),
})

export const ApplicationForm = ({ onClose, refetch, open, application }: Props) => {
  const [createApplication, { isLoading, isError, error, isSuccess }] =
    useCreateApplicationMutation()
  const [
    updateApplication,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateApplicationMutation()

  const [logoImg, setLogoImg] = React.useState<File | null>(null)
  const [bgImg, setBgImg] = React.useState<File | null>(null)
  const [logoImgUrl, setLogoImgUrl] = React.useState<any>(null)
  const [bgImgUrl, setBgImgUrl] = React.useState<any>(null)

  const formik = useFormik({
    initialValues: {
      application: application?.application ?? '',
      homeUrl: application?.homeUrl ?? '',
      vendorLogoPath: application?.vendorLogoPath ?? '',
      backgroundImgPath: application?.backgroundImgPath ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const requestData = {
        application: values.application,
        homeUrl: values.homeUrl,
        vendorLogoPath: values.vendorLogoPath,
        backgroundImgPath: values.backgroundImgPath,
        logoImg: logoImg,
        bgImg: bgImg,
      }

      if (application?.applicationId) {
        updateApplication({
          applicationId: application?.applicationId,
          ...requestData,
        })
      } else {
        createApplication({
          ...requestData,
        })
      }
    },
  })

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  React.useEffect(() => {
    if (application) {
      formik.setValues({
        application: application?.application ?? '',
        homeUrl: application?.homeUrl ?? '',
        vendorLogoPath: application?.vendorLogoPath ?? '',
        backgroundImgPath: application?.backgroundImgPath ?? '',
      })
    }
  }, [application])

  React.useEffect(() => {
    if (!open) resetImg()
  }, [open])

  const resetImg = () => {
    setLogoImg(null)
    setLogoImgUrl(null)
    setBgImg(null)
    setBgImgUrl(null)
  }

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Application Details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id='application'
                name='application'
                value={formik.values.application}
                placeholder='Application'
                onChange={formik.handleChange}
                error={formik.touched.application && Boolean(formik.errors.application)}
                helperText={formik.touched.application && formik.errors.application}
              />
              <TextField
                fullWidth
                id='homeUrl'
                name='homeUrl'
                value={formik.values.homeUrl}
                placeholder='Home URL'
                onChange={formik.handleChange}
                error={formik.touched.homeUrl && Boolean(formik.errors.homeUrl)}
                helperText={formik.touched.homeUrl && formik.errors.homeUrl}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <>
                        <TextField
                          id='logoImg'
                          name='logoImg'
                          type='file'
                          inputProps={{
                            multiple: false,
                          }}
                          sx={{ display: 'none' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            if (e.target.files && e.target.files[0]) {
                              setLogoImgUrl(URL.createObjectURL(e.target.files[0]))
                              setLogoImg(e.target.files[0])
                            }
                          }}
                        />
                        <Button color='primary' variant='contained' component='span'>
                          + Logo
                        </Button>
                      </>
                    }
                    htmlFor='logoImg'
                    label=''
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign='center'>
                    <img
                      src={logoImgUrl ? logoImgUrl : formik.values.vendorLogoPath}
                      width='100px'
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <>
                        <TextField
                          id='bgImg'
                          name='bgImg'
                          type='file'
                          inputProps={{
                            multiple: false,
                          }}
                          sx={{ display: 'none' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            if (e.target.files && e.target.files[0]) {
                              setBgImgUrl(URL.createObjectURL(e.target.files[0]))
                              setBgImg(e.target.files[0])
                            }
                          }}
                        />
                        <Button color='primary' variant='contained' component='span'>
                          + Background
                        </Button>
                      </>
                    }
                    htmlFor='bgImg'
                    label=''
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign='center'>
                    <img
                      src={bgImgUrl ? bgImgUrl : formik.values.backgroundImgPath}
                      width='100px'
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                color='error'
                onClick={reset}
              >
                Cancel
              </Button>
              <Button variant='contained' sx={{ borderRadius: '20px' }} size='large' type='submit'>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
