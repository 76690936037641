/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Divider,
  IconButton,
  Modal,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useForgotPasswordMutation } from '../../../redux/api/authApi'

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
}

interface Props {
  onClose: () => void
  open: boolean
}

export const ForgotPasswordForm = ({ onClose, open }: Props) => {
  const [forgotPassword, { isLoading, isError, error, isSuccess }] = useForgotPasswordMutation()

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('We sent the email! Please check your email inbox.')
      onClose()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      forgotPassword(values)
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pb: 1 }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Forgot Password?
          </Typography>
          <IconButton aria-label='close modal' onClick={onClose} edge='end'>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography variant='body1'>
            Enter your email below to receive password reset instructions
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ mt: 1 }}>
              <Typography variant='subtitle2'>Email:</Typography>
              <TextField
                fullWidth
                id='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Button variant='contained' type='submit' sx={{ mt: 2 }}>
                Send Email
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
