import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IElxData } from '../../types'

interface ILeadTypeState {
  leadType: IElxData | null
}

const initialState: ILeadTypeState = {
  leadType: null,
}

export const leadTypeSlice = createSlice({
  initialState,
  name: 'leadTypeSlice',
  reducers: {
    setLeadType: (state, action: PayloadAction<IElxData>) => {
      state.leadType = action.payload
    },
  },
})

export default leadTypeSlice.reducer

export const { setLeadType } = leadTypeSlice.actions
