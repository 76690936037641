/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Modal,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useChangePasswordMutation } from '../../redux/api/userApi'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}

interface Props {
  onClose: () => void
  open: boolean
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const ChangePasswordForm = ({ onClose, open }: Props) => {
  const [changePassword, { isLoading, isError, error, isSuccess }] = useChangePasswordMutation()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Your password is changed!')
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changePassword({
        old_password: values.old_password,
        password: values.password,
      })
    },
  })

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  const hasMoreEight = formik.values.password.length > 7
  const hasNumber = /\d/.test(formik.values.password)
  const hasCapital = /(?=.*[A-Z])/.test(formik.values.password)
  const hasSpecial = /(?=.*[-+_!@#$%^&*., ?])/.test(formik.values.password)

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Change Password
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id='old_password'
                name='old_password'
                placeholder='Current password'
                type={!showOldPassword ? 'password' : 'text'}
                value={formik.values.old_password}
                onChange={formik.handleChange}
                error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                id='password'
                name='password'
                placeholder='Password'
                type={!showPassword ? 'password' : 'text'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                id='confirm_password'
                name='confirm_password'
                placeholder='Confirm Password'
                type={!showConfirmPassword ? 'password' : 'text'}
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Box
              p={2}
              mt={3}
              bgcolor='rgb(250, 250, 210)'
              borderRadius={2}
              borderColor='rgb(218, 165, 32)'
            >
              <Stack direction='row' spacing={1}>
                {hasMoreEight ? <CheckIcon /> : <CloseIcon />}
                <Typography>Your password must have at least 8 characters.</Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                {hasCapital ? <CheckIcon /> : <CloseIcon />}
                <Typography>Your password must have at least 1 capital letter.</Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                {hasNumber ? <CheckIcon /> : <CloseIcon />}
                <Typography>Your password must have at least 1 number</Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                {hasSpecial ? <CheckIcon /> : <CloseIcon />}
                <Typography>Your password must have at least 1 special character.</Typography>
              </Stack>
            </Box>
            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                color='error'
                onClick={reset}
              >
                Cancel
              </Button>
              <Button variant='contained' sx={{ borderRadius: '20px' }} size='large' type='submit'>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
