import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from '../customOpwFetchBase'
import { ISolicitationPurchaseIncomplete } from '../../../types/reports'

export const solicitationPurchaseIncompleteApi = createApi({
  reducerPath: 'solicitationPurchaseIncompleteApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['Report'],
  endpoints: (builder) => ({
    getSolicitationPurchaseIncompleteList: builder.query<ISolicitationPurchaseIncomplete[], void>({
      query() {
        return {
          url: 'solicitations/get_incomplete',
          credentials: 'include',
        }
      },
      transformResponse: (result: {
        data: { solicitationList: ISolicitationPurchaseIncomplete[] }
      }) => result.data.solicitationList,
    }),
  }),
})

export const { useGetSolicitationPurchaseIncompleteListQuery } = solicitationPurchaseIncompleteApi
