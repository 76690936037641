import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { logout } from '../slice/userSlice'
import { reset } from '../slice/authSlice'

const baseContractUrl = `${process.env.REACT_APP_CONTRACT_SERVICE_API_ENDPOINT}/`

// Create a new mutex
const mutex = new Mutex()

const baseContractQuery = fetchBaseQuery({
  baseUrl: baseContractUrl,
})

const customContractFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseContractQuery(args, api, extraOptions)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((result.error?.data as any)?.message === 'You are not logged in') {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshResult = await baseContractQuery(
          { credentials: 'include', url: 'auth/refresh' },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          // Retry the initial query
          result = await baseContractQuery(args, api, extraOptions)
        } else {
          api.dispatch(reset())
          api.dispatch(logout())
          window.location.href = '/login'
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseContractQuery(args, api, extraOptions)
    }
  }

  return result
}

export default customContractFetchBase
