import React from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { FullScreenLoader, UserRoleTable } from '../../components'
import { useGetUserRoleListQuery, useUpdateUserRoleStatusMutation } from '../../redux/api/roleApi'
import { IUpdateRoleStatusRequest, IUserRole } from '../../types'
import { UserRoleForm } from './UserRoleForm'

export function UserRoleManagementPage() {
  const { isLoading, data, refetch } = useGetUserRoleListQuery()
  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateUserRoleStatusMutation()

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])

  const [isVisible, setIsVisible] = React.useState(false)
  const [editableUserRole, setEditableUserRole] = React.useState<IUserRole | undefined>()

  if (isLoading) {
    return <FullScreenLoader />
  }

  const handleEditable = (data: IUserRole) => {
    setEditableUserRole(data)
    setIsVisible(true)
  }

  const handleStatusUpdate = (data: IUpdateRoleStatusRequest) => {
    updateStatus(data)
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          User Roles
        </Typography>
        <Button
          variant='outlined'
          sx={{ borderRadius: '20px', height: '40px' }}
          startIcon={<AddIcon />}
          onClick={() => {
            setIsVisible(true)
            setEditableUserRole(undefined)
          }}
          size='medium'
        >
          Create
        </Button>
      </Stack>
      <UserRoleTable
        rows={data ?? []}
        editUserRole={(data) => handleEditable(data)}
        disableUserRole={handleStatusUpdate}
      />
      <UserRoleForm
        open={isVisible}
        onClose={() => setIsVisible(false)}
        userRole={editableUserRole}
        refetch={refetch}
      />
    </Container>
  )
}
