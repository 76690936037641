/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Button, TextField, Stack, Typography, Box, Modal } from '@mui/material'
import { toast } from 'react-toastify'

import { useCreateUserRoleMutation, useUpdateUserRoleMutation } from '../../redux/api/roleApi'
import { IUserRole } from '../../types'
import { capitalize, isEmpty } from 'lodash'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}

interface Props {
  userRole?: IUserRole
  onClose: () => void
  refetch: () => void
  open: boolean
}

export const UserRoleForm = ({ onClose, refetch, open, userRole }: Props) => {
  const [newUserRole, setNewUserRole] = React.useState<string>('')
  const [createRole, { isLoading, isError, error: cError, isSuccess }] = useCreateUserRoleMutation()
  const [
    updateRole,
    { isLoading: uLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateUserRoleMutation()

  useEffect(() => {
    setNewUserRole(userRole?.userRole ?? '')
  }, [userRole])

  useEffect(() => {
    if (isSuccess || uIsSuccess) {
      toast.success('Success!')
      onClose()
      refetch()
    }
    if (isError || uIsError) {
      const error = isEmpty(cError) ? uError : cError
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading, uLoading])

  const handleSubmit = () => {
    if (userRole?.userRoleId) {
      updateRole({
        userRoleId: userRole?.userRoleId,
        userRole: capitalize(newUserRole),
      })
    } else {
      createRole({ userRole: capitalize(newUserRole) })
    }
  }

  const handleChange = (value: string) => {
    setNewUserRole(value)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            User Role Details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              value={newUserRole}
              placeholder='Name'
              onChange={(event) => handleChange(event.target.value)}
            />
          </Stack>
          <Box sx={{ textAlign: 'right', mt: '30px' }}>
            <Button
              variant='outlined'
              size='large'
              sx={{ mr: 2, borderRadius: '20px' }}
              color='error'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{ borderRadius: '20px' }}
              size='large'
              onClick={handleSubmit}
              disabled={isEmpty(newUserRole) || isLoading || uLoading}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
