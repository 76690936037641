import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  ISaleSource,
  GenericResponse,
  IUpdateSaleSourceStatusRequest,
  IUpdateSaleSourceRequest,
  ICreateSaleSourceRequest,
  ActiveFlg,
} from '../../types'

export const saleSourceApi = createApi({
  reducerPath: 'saleSourceApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['SaleSource'],
  endpoints: (builder) => ({
    getSaleSourceList: builder.query<ISaleSource[], ActiveFlg | null>({
      query(data) {
        return {
          url: `salesources/get_list/${data ? 'active' : 'all'}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { saleSourceList: ISaleSource[] } }) =>
        result.data.saleSourceList,
    }),
    createSaleSource: builder.mutation<GenericResponse, ICreateSaleSourceRequest>({
      query(data) {
        return {
          url: 'salesources/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateSaleSource: builder.mutation<GenericResponse, IUpdateSaleSourceRequest>({
      query(data) {
        return {
          url: 'salesources/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateSaleSourceStatus: builder.mutation<GenericResponse, IUpdateSaleSourceStatusRequest>({
      query(data) {
        return {
          url: 'salesources/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetSaleSourceListQuery,
  useCreateSaleSourceMutation,
  useUpdateSaleSourceMutation,
  useUpdateSaleSourceStatusMutation,
} = saleSourceApi
