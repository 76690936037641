import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  ISolicitation,
  IYearPlan,
  GenericResponse,
  IUpdateChoiceRequest,
  INotifyRequest,
  ITempSolicitation,
} from '../../types'
import { addYearDetail } from '../slice/yearDetailsSlice'
import { isEmpty, uniqBy } from 'lodash'

export const solicitationApi = createApi({
  reducerPath: 'solicitationApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['Solicitation'],
  endpoints: (builder) => ({
    searchSolicitations: builder.query<ISolicitation[], string>({
      query(path) {
        return {
          url: `solicitations/search${path}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: ISolicitation[] }) => {
        const solicitations: ITempSolicitation = {}
        for (let i = 0; i < result.data.length; i++) {
          const solicitationNumber = result.data[i].solicitationNumber
          if (isEmpty(solicitations[solicitationNumber])) {
            solicitations[solicitationNumber] = result.data[i]
          } else {
            if (isEmpty(solicitations[solicitationNumber].otherSolicitation)) {
              solicitations[solicitationNumber].otherSolicitation = [result.data[i]]
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              solicitations[solicitationNumber].otherSolicitation.push(result.data[i])
            }
          }
        }
        return Object.values(solicitations)
      },
    }),
    getSolicitationById: builder.query<ISolicitation, string | number>({
      query(id) {
        return {
          url: `solicitations/get/${id}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: ISolicitation }) => {
        return result?.data || null
      },
    }),
    getYearDetails: builder.query<IYearPlan[], string | number>({
      query(id) {
        return {
          url: `solicitations/get_year_code?shortCode=${id}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IYearPlan[] }) => {
        return uniqBy(result.data, 'yearNumber')
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addYearDetail(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    updateChoice: builder.mutation<GenericResponse, IUpdateChoiceRequest>({
      query(data) {
        return {
          url: 'solicitations/update_choice',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    notify: builder.mutation<GenericResponse, INotifyRequest>({
      query(data) {
        return {
          url: 'solicitations/notify',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useSearchSolicitationsQuery,
  useGetSolicitationByIdQuery,
  useLazyGetYearDetailsQuery,
  useUpdateChoiceMutation,
  useNotifyMutation,
} = solicitationApi
