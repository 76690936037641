/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react'
import { Stack, Typography, MenuItem, Button, CircularProgress } from '@mui/material'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AddressInput } from './AddressInput'
import { CustomInput, CustomTextField, TextMaskCustom } from './CustomTextField'
import { STATES } from '../../helpers/states'
import { CustomerAddress } from '../../types'

const validationSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('First name is required'),
  address1: yup.string().required('address1 is required'),
  address2: yup.string(),
  city: yup.string().required('city is required'),
  state: yup.string().required('state is required'),
  country: yup.string().required('country is required'),
  zip: yup.string().required('postal code is required'),
  pri_phone: yup.string().required('phone number is required'),
  email: yup.string().required('email is required'),
})

interface Props {
  loading: boolean
  setCustomerAddress: (address: CustomerAddress) => void
}

export const AddressDetails = ({ setCustomerAddress, loading }: Props) => {
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: 'US',
      zip: '',
      pri_phone: '',
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setCustomerAddress(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
        <MarkAsUnreadOutlinedIcon sx={{ color: '#1ba1fa' }} />
        <Typography variant='h5' component='h5'>
          Address
        </Typography>
      </Stack>
      <Stack
        direction='column'
        spacing={2}
        sx={{
          backgroundColor: 'white',
          borderRadius: '6px',
          marginTop: '10px',
          padding: '30px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <CustomTextField
            id='first_name'
            name='first_name'
            placeholder='First Name'
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
            onChange={formik.handleChange}
            required
          />
          <CustomTextField
            id='last_name'
            name='last_name'
            placeholder='Last Name'
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            onChange={formik.handleChange}
            required
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <AddressInput formik={formik} />
          <CustomTextField
            id='address2'
            name='address2'
            placeholder='Apt, Suite, Unit, Building, Floor, etc.'
            error={formik.touched.address2 && Boolean(formik.errors.address2)}
            helperText={formik.touched.address2 && formik.errors.address2}
            onChange={formik.handleChange}
            sx={{ width: '100%' }}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <CustomTextField
              id='city'
              name='city'
              placeholder='City'
              required
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <Stack direction='row' spacing={2}>
              <CustomTextField
                id='state'
                name='state'
                placeholder='Select State'
                sx={{ width: '190px' }}
                required
                select
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                {formik.values.country &&
                  STATES[formik.values.country] &&
                  STATES[formik.values.country].map((state) => (
                    <MenuItem value={state.code} key={state.code}>
                      {state.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
              <CustomTextField
                id='country'
                name='country'
                placeholder='Select Country'
                sx={{ width: '120px' }}
                required
                select
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >
                <MenuItem value='US'>US</MenuItem>
                <MenuItem value='CA'>CA</MenuItem>
                <MenuItem value='MX'>MX</MenuItem>
              </CustomTextField>
            </Stack>
          </Stack>
          <CustomTextField
            id='zip'
            name='zip'
            placeholder='Postal Code'
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={formik.touched.zip && Boolean(formik.errors.zip)}
            helperText={formik.touched.zip && formik.errors.zip}
            required
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <CustomInput
            id='pri_phone'
            name='pri_phone'
            placeholder='Phone Number'
            value={formik.values.pri_phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.pri_phone)}
            inputComponent={TextMaskCustom}
            sx={{ flex: 1 }}
            required
          />
          <CustomTextField
            id='email'
            name='email'
            placeholder='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            sx={{ flex: 1 }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            required
          />
        </Stack>
        <Stack marginTop='30px' direction='row' spacing={2} justifyContent='end'>
          {/* <Button variant='contained' type='submit' sx={{ textTransform: 'none' }}>
            Save
          </Button> */}
          <Button disabled={loading} variant='outlined' type='submit' style={{ height: '37px' }}>
            {loading ? <CircularProgress size={20} /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
