import React from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router'
import { SearchForm } from '../../../components/Electrolux/SearchForm'
import BImage from '../../../assets/images/electrolux/PortalBackground.webp'

export const ElectroluxPortal = () => {
  const navigate = useNavigate()
  const handleSearch = (path: string) => {
    navigate(`/electrolux/search-results${path}`)
  }
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${BImage})`,
          height: 'calc(100vh - 75px)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <SearchForm onSearch={handleSearch} />
      </Box>
    </Box>
  )
}
