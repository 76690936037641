import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { logout } from '../slice/userSlice'
import { reset } from '../slice/authSlice'

const baseOpwUrl = `${process.env.REACT_APP_OPW_SERVER_ENDPOINT}/`

const baseElxUrl = `${process.env.REACT_APP_ELX_SERVER_ENDPOINT}/`

// Create a new mutex
const mutex = new Mutex()

const baseOpwQuery = fetchBaseQuery({
  baseUrl: baseOpwUrl,
})

function getCookie(name: string): string | undefined {
  const cookieString = document.cookie
  const cookies = cookieString.split('; ')
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=')
    if (cookieName === name) {
      return decodeURIComponent(cookieValue)
    }
  }
  return undefined
}

const baseElxQuery = fetchBaseQuery({
  baseUrl: baseElxUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getCookie('access_token')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const customElxFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseElxQuery(args, api, extraOptions)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((result.error?.data as any)?.message === 'You are not logged in') {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshResult = await baseOpwQuery(
          { credentials: 'include', url: 'auth/refresh' },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          // Retry the initial query
          result = await baseElxQuery(args, api, extraOptions)
        } else {
          api.dispatch(reset())
          api.dispatch(logout())
          window.location.href = '/login'
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseElxQuery(args, api, extraOptions)
    }
  }

  return result
}

export default customElxFetchBase
