/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { isEmpty } from 'lodash'

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '500px',
}

const style = {
  borderRadius: '12px',
}

export const MapContainer = (props) => {
  const location = !isEmpty(props.locations) ? props.locations[0] : {}
  return (
    <Map
      containerStyle={containerStyle}
      style={style}
      google={props.google}
      zoom={16}
      initialCenter={{ lat: location.geo_lat, lng: location.geo_lon }}
    >
      {!isEmpty(props.locations) &&
        props.locations.map((location, index) => (
          <Marker
            key={location.global_id}
            name={location.legal_name}
            position={{ lat: location.geo_lat, lng: location.geo_lon }}
            onClick={() => props.setActiveIndex(index)}
          />
        ))}
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(MapContainer)
