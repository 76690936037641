import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material'
import { IFlightTrackerConsumerStatus } from '../../types'
import { useGetFlightTrackerConsumerStatussQuery } from '../../redux/api/flightTrackerConsumerStatusApi'
import { ConsumerPortalStatusForm } from './FlightTrackerConsumerStatusForm'
import { ConsumerPortalStatusTable } from '../../components/Table/FlightTrackerConsumerStatusTable'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'

const styles = {
  container: {
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  searchInput: {
    width: '100%',
    marginRight: '10px',
  },
  addButton: {
    textTransform: 'none',
    textDecoration: 'none',
  },
}

export const ConsumerPortalStatusPage = () => {
  const [searchKey, setSearchKey] = useState('')
  const [editingConsumerStatus, setEditingConsumerStatus] =
    useState<IFlightTrackerConsumerStatus | null>(null)
  const { data, isLoading, refetch } = useGetFlightTrackerConsumerStatussQuery(null)

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
  }

  const handleEditConsumerStatus = (consumerStatus: IFlightTrackerConsumerStatus) => {
    setEditingConsumerStatus(consumerStatus)
  }

  const handleCloseForm = () => {
    setEditingConsumerStatus(null)
    refetch()
  }

  const handleAddConsumerStatus = () => {
    setEditingConsumerStatus({} as IFlightTrackerConsumerStatus)
  }
  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Consumer Portal Flight Tracker
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={handleSearchChange}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px' }}
            startIcon={<AddIcon />}
            onClick={handleAddConsumerStatus}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ConsumerPortalStatusTable
          isLoading={isLoading}
          rows={data || []}
          searchKey={searchKey}
          editConsumerStatus={handleEditConsumerStatus}
          refetch={refetch}
        />
      )}
      {editingConsumerStatus && (
        <ConsumerPortalStatusForm
          data={editingConsumerStatus}
          onClose={handleCloseForm}
          refetch={refetch}
          open={editingConsumerStatus ? true : false}
        />
      )}
    </Container>
  )
}
