import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IApplication,
  GenericResponse,
  IUpdateApplicationStatusRequest,
  IUpdateApplicationRequest,
  ICreateApplicationRequest,
  ActiveFlg,
} from '../../types'
import { setApplication } from '../slice/applicationSlice'

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['Application'],
  endpoints: (builder) => ({
    getApplication: builder.mutation<IApplication, string>({
      query(application) {
        return {
          url: `applications/get?application=${application}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IApplication }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setApplication(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getApplicationList: builder.query<IApplication[], ActiveFlg | null>({
      query(data) {
        return {
          url: `applications/get_list/${data ? 'active' : 'all'}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { applicationList: IApplication[] } }) =>
        result.data.applicationList,
    }),
    createApplication: builder.mutation<GenericResponse, ICreateApplicationRequest>({
      query(data) {
        const formData = new FormData()
        formData.append('application', data.application)
        formData.append('backgroundImgPath', data.backgroundImgPath)
        formData.append('vendorLogoPath', data.vendorLogoPath)
        formData.append('homeUrl', data.homeUrl)
        formData.append('bgImg', data.bgImg ?? '')
        formData.append('logoImg', data.logoImg ?? '')

        return {
          url: 'applications/create',
          method: 'POST',
          body: formData,
          credentials: 'include',
        }
      },
    }),
    updateApplication: builder.mutation<GenericResponse, IUpdateApplicationRequest>({
      query(data) {
        const formData = new FormData()
        formData.append('application', data.application)
        formData.append('applicationId', data.applicationId.toString())
        formData.append('backgroundImgPath', data.backgroundImgPath)
        formData.append('homeUrl', data.homeUrl)
        formData.append('vendorLogoPath', data.vendorLogoPath)
        formData.append('logoImg', data.logoImg ?? '')
        formData.append('bgImg', data.bgImg ?? '')

        return {
          url: 'applications/update',
          method: 'POST',
          body: formData,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateApplicationStatus: builder.mutation<GenericResponse, IUpdateApplicationStatusRequest>({
      query(data) {
        return {
          url: 'applications/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetApplicationMutation,
  useGetApplicationListQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useUpdateApplicationStatusMutation,
} = applicationApi
