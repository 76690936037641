import React from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../redux/store'
import {
  useLazyGetYearDetailsQuery,
  useUpdateChoiceMutation,
} from '../../redux/api/solicitationApi'
import { isEmpty, findIndex } from 'lodash'
import { IYearPlan } from '../../types'
import { PlanValue } from './PlanValue'
import { PaymentNoticeCard } from './PaymentNoticeCard'
import moment from 'moment'

interface CardProps {
  plans: IYearPlan[]
  activeIndex: number
  setActiveIndex: (num: number) => void
  isSelected: boolean
  setIsSelected: () => void
  loading: boolean
  isPaidFlg?: number
  elxContractNumber: string
  endDt?: string
}

const PlanCard = ({
  isSelected,
  activeIndex,
  setActiveIndex,
  plans,
  setIsSelected,
  loading,
  isPaidFlg,
  elxContractNumber,
  endDt,
}: CardProps) => {
  const getYearsLabel = (num: number) => {
    if (num == 1) {
      return '1 Year'
    }
    return `${num} Years`
  }

  const renderButton = () => {
    const today = moment()
    const endDate = moment(endDt)

    if (isPaidFlg === 1 || today.isAfter(endDate)) {
      return null
    }
    if (isSelected) {
      return (
        <Box sx={styles.buttonContainer}>
          <Box sx={styles.selectedMark}>
            <CheckIcon htmlColor='white' />
          </Box>
        </Box>
      )
    }
    return (
      <Box sx={styles.buttonContainer}>
        <Button
          sx={styles.button}
          variant='contained'
          disabled={loading}
          onClick={() => setIsSelected()}
        >
          select plan
        </Button>
      </Box>
    )
  }

  return (
    <Paper sx={{ ...styles.cardContainer, height: isPaidFlg === 1 ? '350px' : '420px' }}>
      <Typography sx={styles.planCardTitle}>
        {isSelected || isPaidFlg === 1 ? 'Selected Plan' : 'Available Plans'}
      </Typography>
      {isSelected || isPaidFlg === 1 ? (
        <Typography sx={styles.selectedLabel}>{`${getYearsLabel(
          plans[activeIndex].yearNumber,
        )} Plan`}</Typography>
      ) : (
        <>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
          <RadioGroup
            aria-labelledby='radio-buttons-group-label'
            value={activeIndex}
            onChange={(event) => setActiveIndex(Number(event.target.value))}
            name='radio-buttons-group'
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {plans.map((plan, index) => (
              <FormControlLabel
                key={plan.solicitationYearDetailId}
                value={index}
                control={<Radio />}
                label={
                  <Typography
                    sx={{ ...styles.radioLabel, fontWeight: index == activeIndex ? 700 : 400 }}
                  >
                    {`${getYearsLabel(plan.yearNumber)}`}
                  </Typography>
                }
                sx={{ marginRight: '0px' }}
              />
            ))}
          </RadioGroup>
        </>
      )}
      <Box>
        {isPaidFlg === 1 ? (
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <Typography sx={styles.label}>Contract:</Typography>
            <Typography sx={styles.contractNumber}>{elxContractNumber}</Typography>
          </Box>
        ) : null}
        {PLANINPUTS.map((field) => (
          <PlanValue
            key={field.label}
            label={field.label}
            isTotal={field.isTotal}
            isPaidFlg={isPaidFlg}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={plans[activeIndex][field.key]}
          />
        ))}
      </Box>
      {renderButton()}
    </Paper>
  )
}

interface Props {
  solicitationNumber: string
  detailId: number
  opw_offer_id?: string
  isPaidFlg?: number
  elxContractNumber: string
  brand?: string
  endDt?: string
}

export const YearDetails = ({
  detailId,
  opw_offer_id,
  solicitationNumber,
  isPaidFlg,
  elxContractNumber,
  brand,
  endDt,
}: Props) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [isSelected, setIsSelected] = React.useState(false)
  const yearDetails = useAppSelector((state) => state.yearDetailSate.yearDetails)
  const [getYearDetails, { isLoading, data }] = useLazyGetYearDetailsQuery()
  const [updateYearPlan, { isLoading: loading, isSuccess, isError, error }] =
    useUpdateChoiceMutation()

  const details = yearDetails[detailId] ?? data

  React.useEffect(() => {
    if (isEmpty(yearDetails[solicitationNumber])) {
      getYearDetails(solicitationNumber)
    }
  }, [solicitationNumber])

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      setIsSelected(true)
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])

  React.useEffect(() => {
    if (!isEmpty(details)) {
      const index = findIndex(details, function (detail) {
        return detail.isPaidFlg == 1
      })
      if (index > 0) {
        setActiveIndex(index)
      }
    }
  }, [details])

  if (isEmpty(details)) {
    return null
  }

  const handleUpdate = () => {
    const activePlan = details[activeIndex]
    updateYearPlan({
      solicitationNumber,
      saleSourceId: 2,
      yearNumber: activePlan.yearNumber,
    })
  }

  if (isLoading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PlanCard
            isSelected={isSelected}
            setIsSelected={handleUpdate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            plans={details}
            loading={loading}
            isPaidFlg={isPaidFlg}
            elxContractNumber={elxContractNumber}
            endDt={endDt}
          />
        </Grid>
        <Grid item xs={6}>
          {isSelected && (
            <PaymentNoticeCard
              opw_offer_id={opw_offer_id}
              years={details[activeIndex].yearNumber}
              brand={brand}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  container: {
    backgroundColor: 'white',
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '150px',
    paddingRight: '150px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70px',
  },
  cardContainer: {
    padding: '40px 30px',
    height: '420px',
  },
  planCardTitle: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '32px',
    color: '#000',
    textAlign: 'center',
  },
  radioLabel: {
    fontFamily: 'Electrolux Sans',
    fontSize: '16px',
    color: '#040F3E',
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '150px',
  },
  button: {
    height: '45px',
    width: '132px',
  },
  selectedMark: {
    backgroundColor: '#74BBCA',
    width: '102px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedLabel: {
    marginTop: '10px',
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '24px',
    color: '#000',
    textAlign: 'center',
    textTransform: 'lowercase',
  },
  description: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 400,
    fontSize: '15px',
    color: '#000',
    textAlign: 'center',
    marginTop: '24px',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    width: '100px',
    textAlign: 'right',
    marginRight: '20px',
  },
  contractNumber: {
    fontWeight: 700,
    fontSize: '28px',
    fontFamily: 'Electrolux Sans',
  },
}

const PLANINPUTS = [
  {
    label: 'Plan cost:',
    isTotal: false,
    key: 'groupGross',
  },
  {
    label: 'Discount:',
    isTotal: false,
    key: 'groupDiscountAmount',
  },
  {
    label: 'Tax:',
    isTotal: false,
    key: 'groupTaxAmount',
  },
  {
    label: 'Total Cost:',
    isTotal: true,
    key: 'groupNetAmount',
  },
]
