import React from 'react'
import { Outlet } from 'react-router-dom'
import { ElectroluxHeader } from './ElectroluxHeader'

export const ElectroluxLayout = () => {
  return (
    <>
      <ElectroluxHeader />
      <Outlet />
    </>
  )
}
