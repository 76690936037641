import * as React from 'react'
import { useNavigate } from 'react-router'
import { AppBar, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { isEmpty, lowerCase } from 'lodash'
import SpeedIcon from '@mui/icons-material/Speed'
import { useAppSelector } from '../../redux/store'

import Logo from '../../assets/images/ServiceAvengerLogo.png'
import { UserAvatar } from './Topbar'
import { Admins } from './Admins'
import { Applications } from './Applications'
import { Reports } from './Reports'

import './index.css'

const styles = {
  header: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '30px',
    paddingRight: '30px',
    maxWidth: 'none',
  },
}

export function Header() {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.userState.user)

  const applicationsList = user?.userRoleList?.filter(
    (role) => lowerCase(role.application) !== 'intranet',
  )

  const admins = user?.userRoleList
    ?.map((role) => {
      if (lowerCase(role.userRole) === 'admin') {
        return lowerCase(role.application)
      }
    })
    ?.filter((app) => !!app)

  const goToHome = () => {
    navigate('/')
  }

  return (
    <>
      <Box maxWidth='xl' sx={styles.header}>
        <img src={Logo} alt='service avenger logo' className='header-logo-image' />
        {!isEmpty(user) && <UserAvatar user={user} />}
      </Box>
      <AppBar position='static'>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <Stack direction='row' spacing={2}>
              {!isEmpty(user) && (
                <Button
                  onClick={goToHome}
                  startIcon={<SpeedIcon />}
                  sx={{ color: 'white', textDecorationLine: 'none' }}
                >
                  Dashboard
                </Button>
              )}
              <Box sx={{ flexGrow: 0 }}>
                {admins && admins.length > 0 && <Admins admins={admins} />}
                {admins && admins.length > 0 && <Reports admins={admins} />}
                {applicationsList && applicationsList.length > 0 && (
                  <Applications applicationsList={applicationsList} />
                )}
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}
