import { createApi } from '@reduxjs/toolkit/query/react'
import customElxFetchBase from './customElxFetchBase'
import {
  IElxData,
  GenericResponse,
  IUpdateElxDataStatusRequest,
  IUpdateElxDataRequest,
  ICreateElxDataRequest,
  ActiveFlg,
} from '../../types'
import { setTriggerFactor } from '../slice/triggerFactorSlice'

export const triggerFactorApi = createApi({
  reducerPath: 'triggerFactorApi',
  baseQuery: customElxFetchBase,
  tagTypes: ['TriggerFactor'],
  endpoints: (builder) => ({
    getTriggerFactor: builder.mutation<IElxData, string>({
      query(triggerFactor) {
        return {
          url: `triggerfactors/get/${triggerFactor}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IElxData }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTriggerFactor(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getTriggerFactorList: builder.query<IElxData[], ActiveFlg | null>({
      query(data) {
        return {
          url: `triggerfactors/get_list/${data ? 'active' : 'all'}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { triggerFactorList: IElxData[] } }) =>
        result.data.triggerFactorList,
    }),
    createTriggerFactor: builder.mutation<GenericResponse, ICreateElxDataRequest>({
      query(data) {
        return {
          url: 'triggerfactors/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateTriggerFactor: builder.mutation<GenericResponse, IUpdateElxDataRequest>({
      query(data) {
        return {
          url: 'triggerfactors/update',
          method: 'POST',
          body: data,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateTriggerFactorStatus: builder.mutation<GenericResponse, IUpdateElxDataStatusRequest>({
      query(data) {
        return {
          url: 'triggerfactors/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetTriggerFactorMutation,
  useGetTriggerFactorListQuery,
  useCreateTriggerFactorMutation,
  useUpdateTriggerFactorMutation,
  useUpdateTriggerFactorStatusMutation,
} = triggerFactorApi
