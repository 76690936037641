import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  GenericResponse,
  ICreateDispatchMessageMasterRequest,
  IDispatchMessageMaster,
  IUpdateDispatchMessageMasterRequest,
  IUpdateDispatchMessageMasterStatusRequest,
} from '../../types'
import { setDispatchMessageMaster } from '../slice/dispatchMessageSlice'

export const dispatchMessageApi = createApi({
  reducerPath: 'dispatchMessageApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['IDispatchMessageMaster'],
  endpoints: (builder) => ({
    getDispatchMessage: builder.query<IDispatchMessageMaster[], void>({
      query() {
        return {
          url: `dispatchmessage/all`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { dispatchMessageList: IDispatchMessageMaster[] } }) => {
        return result.data.dispatchMessageList
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setDispatchMessageMaster(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    createDispatchMessage: builder.mutation<GenericResponse, ICreateDispatchMessageMasterRequest>({
      query(data) {
        return {
          url: 'dispatchmessage/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateDispatchMessage: builder.mutation<GenericResponse, IUpdateDispatchMessageMasterRequest>({
      query(data) {
        return {
          url: 'dispatchmessage/update',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateDispatchMessageStatus: builder.mutation<
      GenericResponse,
      IUpdateDispatchMessageMasterStatusRequest
    >({
      query(data) {
        return {
          url: 'dispatchmessage/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetDispatchMessageQuery,
  useCreateDispatchMessageMutation,
  useUpdateDispatchMessageMutation,
  useUpdateDispatchMessageStatusMutation,
} = dispatchMessageApi
