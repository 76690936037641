import React, { useEffect } from 'react'
import { Button, TextField, Typography, Box, Modal, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../redux/store'
import {
  useCreateFlightTrackerConsumerStatusMutation,
  useUpdateFlightTrackerConsumerStatusMutation,
} from '../../redux/api/flightTrackerConsumerStatusApi'
import { IFlightTrackerConsumerStatus } from '../../types'

// Styles
const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '0',
  },
  content: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: '15px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 0,
  },
}

// Component
interface Props {
  data: IFlightTrackerConsumerStatus | undefined | null
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  flightTrackerStatusConsumer: yup.string().required('Service Order status is required'),
  sortOrder: yup
    .number()
    .required('Sort order is required')
    .max(1000, 'Max sort is 1000')
    .min(0, 'Minimum sort is 0'),
})

export const ConsumerPortalStatusForm = ({ onClose, refetch, open, data }: Props) => {
  // State and API hooks
  const [createFlightTrackerConsumerStatus, { isLoading: cIsLoading }] =
    useCreateFlightTrackerConsumerStatusMutation()
  const [updateFlightTrackerConsumerStatus, { isLoading: uIsLoading }] =
    useUpdateFlightTrackerConsumerStatusMutation()
  const user = useAppSelector((state) => state.userState.user)

  // Formik
  const formik = useFormik({
    initialValues: {
      flightTrackerStatusConsumer: data?.flightTrackerStatusConsumer || '',
      sortOrder: data?.sortOrder || 10,
    },
    validationSchema,
    onSubmit: (values) => {
      if (data?.flightTrackerStatusConsumerId) {
        // Update
        const requestData = {
          flightTrackerStatusConsumer: values.flightTrackerStatusConsumer,
          sortOrder: values.sortOrder,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
          flightTrackerStatusConsumerId: data.flightTrackerStatusConsumerId,
          application: 'Intranet',
        }
        updateFlightTrackerConsumerStatus(requestData)
          .then(() => {
            toast.success('Consumer portal status updated successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to update consumer portal status')
            console.error(error)
          })
      } else {
        // Create
        const requestData = {
          flightTrackerStatusConsumer: values.flightTrackerStatusConsumer,
          sortOrder: values.sortOrder,
          application: 'Intranet',
          createdBy: `${user?.firstName} ${user?.lastName}`,
        }
        createFlightTrackerConsumerStatus(requestData)
          .then(() => {
            toast.success('Consumer portal status created successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to create consumer portal status')
            console.error(error)
          })
      }
    },
  })

  // Effect to reset form on open/close
  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  // Handlers
  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            {data?.flightTrackerStatusConsumerId
              ? 'Edit Consumer Portal Status'
              : 'Create Consumer Portal Status'}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <form onSubmit={formik.handleSubmit}>
            {/* Form fields */}
            <TextField
              fullWidth
              id='flightTrackerStatusConsumer'
              name='flightTrackerStatusConsumer'
              label='Consumer Portal Status'
              autoComplete='off'
              value={formik.values.flightTrackerStatusConsumer}
              onChange={formik.handleChange}
              error={
                formik.touched.flightTrackerStatusConsumer &&
                Boolean(formik.errors.flightTrackerStatusConsumer)
              }
              helperText={
                formik.touched.flightTrackerStatusConsumer &&
                formik.errors.flightTrackerStatusConsumer
              }
              style={{ marginBottom: 3 }}
            />
            <TextField
              fullWidth
              id='sortOrder'
              name='sortOrder'
              label='Sort Order'
              autoComplete='off'
              value={formik.values.sortOrder}
              onChange={formik.handleChange}
              error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
              helperText={formik.touched.sortOrder && formik.errors.sortOrder}
            />
            <Box sx={{ textAlign: 'right', marginTop: '20px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='large'
                type='submit'
                disabled={cIsLoading || uIsLoading}
                sx={{ borderRadius: '20px' }}
              >
                Submit
                {(cIsLoading || uIsLoading) && <CircularProgress size={20} sx={{ ml: 1 }} />}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
