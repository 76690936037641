import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  time: string
  disabled: boolean
  active: boolean
  onSelect: () => void
}

export const TimeLabel = ({ time, disabled, active, onSelect }: Props) => {
  const backgroundColor = active ? 'rgb(25, 118, 210)' : disabled ? 'gray' : 'white'
  return (
    <Box
      sx={{
        border: '1px solid #dedede',
        padding: '4px 12px',
        borderRadius: '20px',
        backgroundColor,
        cursor: 'pointer',
        minWidth: '70px',
        display: 'flex',
        justifyContent: 'center',
      }}
      onClick={() => {
        if (!disabled) {
          onSelect()
        }
      }}
    >
      <Typography>{time}</Typography>
    </Box>
  )
}
