/* eslint-disable no-case-declarations */
import React from 'react'
import { Box, Typography, Button, RadioGroup, FormControlLabel, Radio, Paper } from '@mui/material'
import { EInput } from './EInput'
import { isEmpty } from 'lodash'

interface Props {
  onSearch: (value: string) => void
}

interface ISearchForm {
  shortCode: string
  serial: string
  lastName: string
  email: string
  address: string
  zipCode: string
}

function getDisable(searchBy: string, formData: ISearchForm) {
  switch (searchBy) {
    case 'offer':
      if (isEmpty(formData.shortCode)) {
        return true
      }
      if (formData.shortCode.length < 9) {
        return true
      }
      return false
    case 'product':
      return isEmpty(formData.serial)
    default:
      const { lastName, email, zipCode } = formData
      if (!isEmpty(email)) return false
      return isEmpty(lastName) || isEmpty(zipCode)
  }
}

export const SearchForm = ({ onSearch }: Props) => {
  const [formState, setFormState] = React.useState<ISearchForm>({
    shortCode: '',
    serial: '',
    lastName: '',
    email: '',
    address: '',
    zipCode: '',
  })
  const [searchBy, setSearchBy] = React.useState('offer')

  const onChangeFormState = (field: string, value: string) => {
    setFormState({ ...formState, [field]: value })
  }

  const handleSearch = () => {
    switch (searchBy) {
      case 'offer':
        onSearch(`?shortCode=${formState.shortCode}`)
        return
      case 'product':
        onSearch(`?serial=${formState.serial}`)
        return
      default:
        onSearch(
          `?lastName=${formState.lastName}&email=${formState.email}&zipCode=${formState.zipCode}&address=${formState.address}`,
        )
        return
    }
  }

  const renderForm = () => {
    switch (searchBy) {
      case 'offer':
        return (
          <Box sx={{ marginTop: '32px', marginBottom: '32px' }}>
            <EInput
              label='* Invitation ID'
              value={formState.shortCode}
              placeholder='Enter Invitation ID without the dashes'
              onChange={(value) => onChangeFormState('shortCode', value)}
            />
          </Box>
        )
      case 'product':
        return (
          <Box sx={{ marginTop: '32px', marginBottom: '32px' }}>
            <EInput
              label='* Invitation ID'
              value={formState.serial}
              placeholder='Enter Product Serial'
              onChange={(value) => onChangeFormState('serial', value)}
            />
          </Box>
        )
      default:
        return (
          <Box sx={{ marginTop: '32px', marginBottom: '32px' }}>
            <EInput
              label='* Consumer Last Name'
              value={formState.lastName}
              placeholder='Enter Consumer Last Name'
              onChange={(value) => onChangeFormState('lastName', value)}
            />
            <EInput
              label='Consumer Address:'
              value={formState.address}
              placeholder='Enter Consumer Address:'
              onChange={(value) => onChangeFormState('address', value)}
            />
            <EInput
              label='* Consumer Zip Code:'
              value={formState.zipCode}
              placeholder='Enter Consumer Zip Code:'
              onChange={(value) => onChangeFormState('zipCode', value)}
            />
            <EInput
              label='* Consumer Email:'
              value={formState.email}
              placeholder='Enter Consumer Email:'
              onChange={(value) => onChangeFormState('email', value)}
            />
          </Box>
        )
    }
  }

  const disableButton = getDisable(searchBy, formState)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography sx={styles.title}>Search</Typography>
      </Box>
      <Paper sx={styles.content}>
        <RadioGroup
          aria-labelledby='radio-buttons-group-label'
          value={searchBy}
          onChange={(event) => setSearchBy(event.target.value)}
          name='radio-buttons-group'
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value='offer'
            control={<Radio />}
            label={
              <Typography sx={searchBy === 'offer' ? styles.activeRadio : styles.deActiveRadio}>
                by Invitation ID
              </Typography>
            }
          />
          <FormControlLabel
            value='customer'
            control={<Radio />}
            label={
              <Typography sx={searchBy === 'customer' ? styles.activeRadio : styles.deActiveRadio}>
                by Customer
              </Typography>
            }
          />
          <FormControlLabel
            value='product'
            control={<Radio />}
            label={
              <Typography sx={searchBy === 'product' ? styles.activeRadio : styles.deActiveRadio}>
                by Product
              </Typography>
            }
          />
        </RadioGroup>
        {renderForm()}
        <Box sx={styles.center}>
          <Button
            variant='contained'
            size='large'
            sx={styles.button}
            disabled={disableButton}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

const styles = {
  container: {
    marginTop: '140px',
  },
  header: {
    backgroundColor: '#040F3E',
    height: '78px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Electrolux Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '31.98px',
    color: 'white',
  },
  activeRadio: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '18px',
  },
  deActiveRadio: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 400,
    fontSize: '18px',
  },
  content: {
    padding: '40px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    background: '#040F3E',
    fontWeight: 700,
    fontSize: '18px',
    borderRadius: '0px',
    textTransform: 'capitalize',
    width: '244px',
    textDecoration: 'underline',
  },
}
