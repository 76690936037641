import React from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { FullScreenLoader, SaleSourceTable } from '../../components'
import { SaleSourceForm } from './SaleSourceForm'

import { ISaleSource, IUpdateSaleSourceStatusRequest } from '../../types'
import {
  useGetSaleSourceListQuery,
  useUpdateSaleSourceStatusMutation,
} from '../../redux/api/saleSourceApi'

export function SaleSourceManagementPage() {
  const { isLoading, data, refetch } = useGetSaleSourceListQuery(null)
  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateSaleSourceStatusMutation()
  const [searchKey, setSearchKey] = React.useState<string>('')

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])
  const [isVisible, setIsVisible] = React.useState(false)
  const [editableSaleSource, setEditableSaleSource] = React.useState<ISaleSource | undefined>()

  if (isLoading || !data || loading) {
    return <FullScreenLoader />
  }

  const handleEditable = (data: ISaleSource) => {
    setEditableSaleSource(data)
    setIsVisible(true)
  }

  const handleStatusUpdate = (data: IUpdateSaleSourceStatusRequest) => {
    updateStatus(data)
  }
  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Sale Sources
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px' }}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsVisible(true)
              setEditableSaleSource(undefined)
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <SaleSourceTable
        rows={data ?? []}
        searchKey={searchKey}
        editSaleSource={(data) => handleEditable(data)}
        disableSaleSource={handleStatusUpdate}
      />
      <SaleSourceForm
        open={isVisible}
        onClose={() => setIsVisible(false)}
        saleSource={editableSaleSource}
        refetch={refetch}
      />
    </Container>
  )
}
