import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import { IUser, GenericResponse, IUserRequest, IUpdateStatusRequest } from '../../types'

export const agentApi = createApi({
  reducerPath: 'agentApi',
  baseQuery: customOpwFetchBase,
  endpoints: (builder) => ({
    getUsers: builder.query<IUser[], void>({
      query() {
        return {
          url: `agent/users_list`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { users: IUser[] } }) => result.data.users,
    }),
    createUser: builder.mutation<GenericResponse, IUserRequest>({
      query(data) {
        return {
          url: 'agent/user_create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUser: builder.mutation<GenericResponse, IUserRequest>({
      query(data) {
        return {
          url: 'agent/user_update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUserStatus: builder.mutation<GenericResponse, IUpdateStatusRequest>({
      query(data) {
        return {
          url: 'agent/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
} = agentApi
