import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IFlightTrackerConsumerStatus,
  ICreateFlightTrackerConsumerStatusRequest,
  IUpdateFlightTrackerConsumerStatusRequest,
  IUpdateFlightTrackerConsumerStatusStatusRequest,
  ActiveFlg,
} from '../../types'

// Assuming GenericResponse is a type representing a generic API response
interface GenericResponse {
  status: string
  message: string
}

// Slice action placeholder
const setFlightTrackerConsumerStatuss = (data: IFlightTrackerConsumerStatus[]) => ({
  type: 'setFlightTrackerConsumerStatuss',
  payload: data,
})

export const flightTrackerConsumerStatusApi = createApi({
  reducerPath: 'flightTrackerConsumerStatusApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['IFlightTrackerConsumerStatus'],
  endpoints: (builder) => ({
    getFlightTrackerConsumerStatuss: builder.query<
      IFlightTrackerConsumerStatus[],
      ActiveFlg | null
    >({
      query(isActiveFlg) {
        return {
          url: 'flighttrackerconsumer/get',
          method: 'GET',
          credentials: 'include',
          params: {
            isActiveFlg: isActiveFlg,
          },
        }
      },
      transformResponse: (result: { data: { request: IFlightTrackerConsumerStatus[] } }) => {
        return result.data.request
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setFlightTrackerConsumerStatuss(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    createFlightTrackerConsumerStatus: builder.mutation<
      GenericResponse,
      ICreateFlightTrackerConsumerStatusRequest
    >({
      query(data) {
        return {
          url: 'flighttrackerconsumer/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateFlightTrackerConsumerStatus: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerConsumerStatusRequest
    >({
      query(data) {
        return {
          url: 'flighttrackerconsumer/update',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateFlightTrackerConsumerStatusStatus: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerConsumerStatusStatusRequest
    >({
      query(data) {
        return {
          url: 'flighttrackerconsumer/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetFlightTrackerConsumerStatussQuery,
  useCreateFlightTrackerConsumerStatusMutation,
  useUpdateFlightTrackerConsumerStatusMutation,
  useUpdateFlightTrackerConsumerStatusStatusMutation,
} = flightTrackerConsumerStatusApi
