export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? '', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
}

export const electroluxMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ELECTROLUX_MSAL_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_ELECTROLUX_MSAL_AUTHORITY ?? '', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}
