import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApplication } from '../../types'

interface IApplicationState {
  application: IApplication | null
}

const initialState: IApplicationState = {
  application: null,
}

export const applicationSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setApplication: (state, action: PayloadAction<IApplication>) => {
      state.application = action.payload
    },
  },
})

export default applicationSlice.reducer

export const { setApplication } = applicationSlice.actions
