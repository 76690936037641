import React from 'react'
import { useNavigate } from 'react-router'
import { Container, Button, Box, Typography } from '@mui/material'

const styles = {
  container: {
    backgroundColor: '#040F3E',
    height: '84px',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    fontWeight: 400,
    fontFamily: 'Helvetica Now Display',
    fontSize: '15px',
  },
  divider: {
    color: 'white',
    marginRight: '8px',
    fontWeight: 400,
    fontFamily: 'Helvetica Now Display',
    fontSize: '15px',
  },
  text: {
    color: 'white',
    fontWeight: 400,
    fontFamily: 'Helvetica Now Display',
    fontSize: '15px',
  },
}

export const SearchHeader = () => {
  const navigate = useNavigate()
  const handleback = () => {
    navigate(`/electrolux/home`)
  }
  return (
    <Box sx={styles.container}>
      <Container maxWidth='xl'>
        <Box sx={styles.content}>
          <Button variant='text' sx={styles.button} onClick={handleback}>
            Home
          </Button>
          <Typography sx={styles.divider}>/</Typography>
          <Typography sx={styles.text}>Search results</Typography>
        </Box>
      </Container>
    </Box>
  )
}
