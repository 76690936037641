/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { useCookies } from 'react-cookie'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { userApi } from '../../redux/api/userApi'
import { FullScreenLoader } from '../Loader'
import { isEmpty } from 'lodash'
import { IUserRole } from '../../types'

const getAccessValid = (
  userRoleList: IUserRole[] = [],
  allowedRoleList: string[] = [],
  allowedApplicationList: string[] = [],
) => {
  if (isEmpty(allowedRoleList)) {
    return true
  }
  if (!isEmpty(allowedRoleList) && isEmpty(userRoleList)) {
    return false
  }

  if (isEmpty(allowedApplicationList)) {
    return userRoleList.filter((userRole) => allowedRoleList.includes(userRole.userRole)).length > 0
  }

  return (
    userRoleList.filter(
      (userRole) =>
        allowedRoleList.includes(userRole.userRole) &&
        allowedApplicationList.includes(userRole.application),
    ).length > 0
  )
}

interface Props {
  allowedUserRoleList?: string[]
  allowedApplicationList?: string[]
}

export const ProtectedPage = ({ allowedUserRoleList, allowedApplicationList }: Props) => {
  const [cookies] = useCookies(['logged_in'])
  const location = useLocation()

  const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  const loading = isLoading || isFetching

  const user = userApi.endpoints.getMe.useQueryState(null, {
    //@ts-ignore
    selectFromResult: ({ data }) => data,
  })

  if (loading) {
    return <FullScreenLoader />
  }

  const isValid = getAccessValid(
    // @ts-ignore
    user?.userRoleList ?? [],
    allowedUserRoleList,
    allowedApplicationList,
  )

  return (cookies.logged_in || user) && isValid ? (
    <Outlet />
  ) : cookies.logged_in && user ? (
    <Navigate to='/' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  )
}
