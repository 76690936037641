import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFlightTrackerConsumerStatus, ActiveFlg } from '../../types'

interface IFlightTrackerConsumerStatussState {
  consumerStatusList: IFlightTrackerConsumerStatus[] | null
}

const initialState: IFlightTrackerConsumerStatussState = {
  consumerStatusList: null,
}

export const flightTrackerConsumerStatusSlice = createSlice({
  initialState,
  name: 'flightTrackerConsumerStatusSlice',
  reducers: {
    setFlightTrackerConsumerStatuss: (
      state,
      action: PayloadAction<IFlightTrackerConsumerStatus[]>,
    ) => {
      state.consumerStatusList = action.payload
    },
    updateFlightTrackerConsumerStatus: (
      state,
      action: PayloadAction<IFlightTrackerConsumerStatus>,
    ) => {
      if (state.consumerStatusList) {
        const index = state.consumerStatusList.findIndex(
          (consumerStatusList) =>
            consumerStatusList.flightTrackerStatusConsumerId ===
            action.payload.flightTrackerStatusConsumerId,
        )
        if (index !== -1) {
          state.consumerStatusList[index] = action.payload
        }
      }
    },
    updateFlightTrackerConsumerStatusStatus: (
      state,
      action: PayloadAction<{ consumerStatusIdList: number[]; isActiveFlg: ActiveFlg }>,
    ) => {
      if (state.consumerStatusList) {
        state.consumerStatusList = state.consumerStatusList.map((consumerStatus) =>
          action.payload.consumerStatusIdList.includes(consumerStatus.flightTrackerStatusConsumerId)
            ? { ...consumerStatus, isActiveFlg: action.payload.isActiveFlg }
            : consumerStatus,
        )
      }
    },
  },
})

export default flightTrackerConsumerStatusSlice.reducer

export const {
  setFlightTrackerConsumerStatuss,
  updateFlightTrackerConsumerStatus,
  updateFlightTrackerConsumerStatusStatus,
} = flightTrackerConsumerStatusSlice.actions
