import { createApi } from '@reduxjs/toolkit/query/react'
import { setUser } from '../slice/userSlice'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IUser,
  IUserRequest,
  IChangePasswordRequest,
  GenericResponse,
  IUpdateStatusRequest,
} from '../../types'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['IUser'],
  endpoints: (builder) => ({
    getMe: builder.query<IUser, null>({
      query() {
        return {
          url: 'users/me',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { user: IUser } }) => result.data.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getUsers: builder.query<IUser[], void>({
      query() {
        return {
          url: 'users/all',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { users: IUser[] } }) => result.data.users,
    }),
    createUser: builder.mutation<GenericResponse, IUserRequest>({
      query(data) {
        return {
          url: 'users/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUser: builder.mutation<GenericResponse, IUserRequest>({
      query(data) {
        return {
          url: 'users/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUserStatus: builder.mutation<GenericResponse, IUpdateStatusRequest>({
      query(data) {
        return {
          url: 'users/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    changePassword: builder.mutation<GenericResponse, IChangePasswordRequest>({
      query({ old_password, password }) {
        return {
          url: `users/changePassword`,
          method: 'PATCH',
          body: { old_password, password },
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserStatusMutation,
  useUpdateUserMutation,
  useChangePasswordMutation,
} = userApi
