import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import useMediaQuery from '@mui/material/useMediaQuery'
import { TimeLabel } from './TimeLabel'
import { Time } from './ScheduleAppointment'

const useStyles = makeStyles(() => ({
  header: {
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dedede',
    minWidth: '100px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  container: {
    flex: 1,
  },
}))

interface Props {
  day: string
  date: string
  slots: Time[]
  selectedTime?: Time
  onSelect: (value: Time) => void
}

export const DateList = ({ day, date, slots, selectedTime, onSelect }: Props) => {
  const isDeskTop = useMediaQuery('(min-width:600px)')
  const classes = useStyles()
  return (
    <Stack direction={{ xs: 'row', sm: 'column' }} className={classes.container}>
      <Box className={classes.header}>
        <Typography>{date}</Typography>
        <Typography>{day}</Typography>
      </Box>
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        className={isDeskTop ? 'hide-scroll-bar' : 'horizontal-date-list'}
        alignItems='center'
        spacing={1}
      >
        {slots.map((slot) => (
          <TimeLabel
            key={slot.local}
            time={moment(slot.local).format('LT')}
            disabled={moment().isAfter(slot.local, 'minutes')}
            active={selectedTime?.local === slot.local}
            onSelect={() => onSelect(slot)}
          />
        ))}
      </Stack>
    </Stack>
  )
}
