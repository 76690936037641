/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import { capitalize } from 'lodash'
import { IUpdateStatusRequest, IUser, IUserRole } from '../../types'
import { EditButton } from '../Buttons'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  id: keyof IUser
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'firstName',
    label: 'First Name',
  },
  {
    id: 'lastName',
    label: 'Last Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'createdOn',
    label: 'Created',
  },
  {
    id: 'createdBy',
    label: 'Created By',
  },
  {
    id: 'updatedOn',
    label: 'Updated',
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
  },
]

interface UserTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IUser) => void
  order: Order
  orderBy: string
}

function UserTableHead(props: UserTableHeadProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof IUser) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        <TableCell padding='checkbox'></TableCell>
        <TableCell></TableCell>
        <TableCell sx={{ border: 'none', color: 'white' }}>User Role</TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props {
  rows: IUser[]
  disableUser: (data: IUpdateStatusRequest) => void
  editUser: (data: IUser) => void
  searchKey: string
}

export const UserTable = ({ rows, searchKey, disableUser, editUser }: Props) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof IUser>('firstName')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUser) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const showApplicationWithRoles = (userRoleList: IUserRole[]) => {
    return (
      <>
        {userRoleList?.length > 0 &&
          userRoleList?.map((userRole, idx) => (
            <Chip
              label={`${capitalize(userRole.userRole)} at ${userRole.application}`}
              sx={{ marginBottom: '2px' }}
              color='warning'
              size='small'
              key={idx}
            />
          ))}
      </>
    )
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='userTable'>
            <UserTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {/* @ts-ignore */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((user) => String(user[orderBy]).includes(searchKey))
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={`${row.userId}_${index}`}>
                      <TableCell>
                        <EditButton
                          //@ts-ignore
                          edit={() => editUser(row)}
                          disable={() =>
                            disableUser({
                              userId: row.userId,
                              flag: row.isActiveFlg ? 0 : 1,
                            })
                          }
                          isActive={row.isActiveFlg}
                        />
                      </TableCell>
                      <TableCell>
                        {row?.isActiveFlg ? (
                          <Chip label='Active' color='primary' size='small' />
                        ) : (
                          <Chip label='Inactive' color='error' size='small' />
                        )}
                      </TableCell>
                      {/* @ts-ignore */}
                      <TableCell>{showApplicationWithRoles(row?.userRoleList ?? [])}</TableCell>
                      <TableCell>{row?.firstName}</TableCell>
                      <TableCell>{row?.lastName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{moment(row?.createdOn).format('L')}</TableCell>
                      <TableCell>{row?.createdBy ? row?.createdBy : ''}</TableCell>
                      <TableCell>
                        {row?.updatedOn ? moment(row.updatedOn).format('L') : ''}
                      </TableCell>
                      <TableCell>{row?.updatedBy ? row?.updatedBy : ''}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
