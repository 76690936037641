import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import KeyIcon from '@mui/icons-material/Key'
import LogoutIcon from '@mui/icons-material/Logout'
import { isEmpty } from 'lodash'

import { useAppSelector } from '../../../redux/store'
import { useLogoutUserMutation } from '../../../redux/api/authApi'
import { ChangePasswordForm } from '../../Layout/ChangePasswordForm'
import ElectroluxHeaderLogo from '../../../assets/images/electrolux/HeaderLogo.png'
import FrigidaireHeaderLogo from '../../../assets/images/electrolux/FrigidaireLogo.png'
import OnpointHeaderLogo from '../../../assets/images/HeaderLogo.png'

export const ElectroluxHeader = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const user = useAppSelector((state) => state.userState.user)
  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation()

  React.useEffect(() => {
    if (isSuccess) {
      window.location.href = '/login'
    }
  }, [isLoading])

  if (isEmpty(user)) {
    return null
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleLogout = () => {
    logoutUser()
    handleCloseUserMenu()
  }

  return (
    <AppBar
      position='static'
      sx={{ backgroundColor: 'white', paddingTop: '16px', paddingBottom: '10px', height: '75px' }}
    >
      <Container maxWidth='xl' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '40px' }}></Box>
        <Box>
          <a href='/electrolux/home'>
            <img src={ElectroluxHeaderLogo} alt='electrolux logo' height={44} />
          </a>
          <a href='/'>
            <img
              src={FrigidaireHeaderLogo}
              alt='frigidaire logo'
              height={44}
              className='frigidaire-header-logo'
            />
          </a>
          <a href='/'>
            <img
              src={OnpointHeaderLogo}
              alt='onpoint logo'
              height={44}
              className='electrolux-header-logo'
            />
          </a>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {user?.createdBy !== 'Microsoft' && (
              <MenuItem onClick={() => setIsVisible(true)}>
                <KeyIcon sx={{ mr: 1 }} />
                <Typography textAlign='center'>Change Password</Typography>
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <LogoutIcon sx={{ mr: 1 }} />
              <Typography textAlign='center'>Log Out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Container>
      <ChangePasswordForm open={isVisible} onClose={() => setIsVisible(false)} />
    </AppBar>
  )
}
