import { createApi } from '@reduxjs/toolkit/query/react'
import customElxFetchBase from './customElxFetchBase'
import {
  IElxData,
  GenericResponse,
  IUpdateElxDataStatusRequest,
  IUpdateElxDataRequest,
  ICreateElxDataRequest,
  ActiveFlg,
} from '../../types'
import { setLeadType } from '../slice/leadTypeSlice'

export const leadTypeApi = createApi({
  reducerPath: 'leadTypeApi',
  baseQuery: customElxFetchBase,
  tagTypes: ['LeadType'],
  endpoints: (builder) => ({
    getLeadType: builder.mutation<IElxData, string>({
      query(leadType) {
        return {
          url: `leadtypes/get/${leadType}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IElxData }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setLeadType(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getLeadTypeList: builder.query<IElxData[], ActiveFlg | null>({
      query(data) {
        return {
          url: `leadtypes/get_list/${data ? 'active' : 'all'}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { leadTypeList: IElxData[] } }) =>
        result.data.leadTypeList,
    }),
    createLeadType: builder.mutation<GenericResponse, ICreateElxDataRequest>({
      query(data) {
        return {
          url: 'leadtypes/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateLeadType: builder.mutation<GenericResponse, IUpdateElxDataRequest>({
      query(data) {
        return {
          url: 'leadtypes/update',
          method: 'POST',
          body: data,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateLeadTypeStatus: builder.mutation<GenericResponse, IUpdateElxDataStatusRequest>({
      query(data) {
        return {
          url: 'leadtypes/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetLeadTypeMutation,
  useGetLeadTypeListQuery,
  useCreateLeadTypeMutation,
  useUpdateLeadTypeMutation,
  useUpdateLeadTypeStatusMutation,
} = leadTypeApi
