import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { IUserRole } from '../../types'

interface Props {
  applicationsList: IUserRole[]
}

export function Applications({ applicationsList }: Props) {
  const [anchorElApplication, setAnchorElApplication] = React.useState<null | HTMLElement>(null)

  const handleOpenApplicationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElApplication(event.currentTarget)
  }

  const handleCloseApplicationMenu = () => {
    setAnchorElApplication(null)
  }

  return (
    <>
      <Button
        onClick={handleOpenApplicationMenu}
        sx={{ color: 'white', textDecorationLine: 'none' }}
        startIcon={<DesktopWindowsOutlinedIcon />}
        endIcon={anchorElApplication ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        Applications
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElApplication}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElApplication)}
        onClose={handleCloseApplicationMenu}
      >
        {applicationsList.map((role) => (
          <a href={role.homeUrl} target='_self' key={role.application} rel='noreferrer'>
            <MenuItem onClick={handleCloseApplicationMenu}>
              <Typography textAlign='center'>{role.application}</Typography>
            </MenuItem>
          </a>
        ))}
      </Menu>
    </>
  )
}
