import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IElxData } from '../../types'

interface IMarketingTypeState {
  marketingType: IElxData | null
}

const initialState: IMarketingTypeState = {
  marketingType: null,
}

export const marketingTypeSlice = createSlice({
  initialState,
  name: 'marketingTypeSlice',
  reducers: {
    setMarketingType: (state, action: PayloadAction<IElxData>) => {
      state.marketingType = action.payload
    },
  },
})

export default marketingTypeSlice.reducer

export const { setMarketingType } = marketingTypeSlice.actions
