import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDispatchMessageMaster } from '../../types'

interface IDispatchMessageState {
  masters: IDispatchMessageMaster[] | null
}

const initialState: IDispatchMessageState = {
  masters: null,
}

export const dispatchMessageSlice = createSlice({
  initialState,
  name: 'dispatchMessageSlice',
  reducers: {
    setDispatchMessageMaster: (state, action: PayloadAction<IDispatchMessageMaster[]>) => {
      state.masters = action.payload
    },
  },
})

export default dispatchMessageSlice.reducer

export const { setDispatchMessageMaster } = dispatchMessageSlice.actions
