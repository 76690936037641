import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IDispatchMessageBrandDetails } from '../../types'

interface BrandAutocompleteProps {
  mockOptions: IDispatchMessageBrandDetails[]
  formik: {
    values: {
      brandCode: string
    }
    setFieldValue: (field: string, value: string) => void
  }
}

const BrandAutocomplete: React.FC<BrandAutocompleteProps> = ({ mockOptions, formik }) => {
  const [selectedBrand, setSelectedBrand] = useState<string>(formik.values.brandCode)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    setSelectedBrand(formik.values.brandCode)
  }, [formik.values.brandCode])
  const handleBrandChange = (event: any, value: IDispatchMessageBrandDetails | null) => {
    if (value) {
      setSelectedBrand(value.brandCode || '')
      setInputValue(value.brand)
      formik.setFieldValue('brandCode', value.brandCode || '')
    } else {
      setSelectedBrand('')
      setInputValue('')
      formik.setFieldValue('brandCode', '')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, newInputValue: string) => {
    setInputValue(newInputValue)
  }
  const handleInputChangeAdapter = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    const inputEvent = event as React.ChangeEvent<HTMLInputElement>
    handleInputChange(inputEvent, newInputValue)
  }

  const [options, setOptions] = useState<IDispatchMessageBrandDetails[]>([])
  useEffect(() => {
    const filteredOptions = mockOptions.filter((option) =>
      option.brand.toLowerCase().includes(inputValue.toLowerCase()),
    )
    setOptions(filteredOptions)
  }, [inputValue])

  return (
    <Autocomplete
      id='brand-autocomplete'
      options={options}
      getOptionLabel={(option) => option.brand}
      value={options.find((option) => option.brandCode === selectedBrand) || null}
      inputValue={inputValue}
      onChange={handleBrandChange}
      onInputChange={handleInputChangeAdapter}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label='Brand'
          variant='outlined'
          InputProps={{ ...params.InputProps, readOnly: true }}
        />
      )}
    />
  )
}

export default BrandAutocomplete
