/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  styled,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import {
  useCreateSurveyMonkeyMasterMutation,
  useUpdateSurveyMonkeyMasterMutation,
} from '../../redux/api/surveyMonkeyMasterApi'
import { ISurveyMonkeyMaster } from '../../types'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '10px',
  },
  content: {
    maxHeight: '80vh',
    overflow: 'auto',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
  jsonContainer: {
    borderRadius: 'inherit',
    border: '1px solid #0000003b',
    padding: '20px 10px 10px 10px',
  },
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

interface Props {
  surveyMonkeyMaster: ISurveyMonkeyMaster | undefined
  onClose: () => void
  refetch: () => void
  open: boolean
}

function hasSpecialCharacters(input: string | undefined) {
  return input !== input?.replace(/[^a-zA-Z0-9 ]/g, '')
}

function hasDuplicate(array: { [key: string]: string }[], key: string) {
  if (!array) return false
  const seen = new Set()
  for (const item of array) {
    const value = item[key]
    if (seen.has(value)) {
      return true
    }
    seen.add(value)
  }
  return false
}

const validationSchema = yup.object({
  kProgram: yup
    .string()
    .required('Kustomer Program is required')
    .test(
      'kProgram',
      'Kustomer Program does not allow special characters',
      (value) => !hasSpecialCharacters(value),
    ),
  templateId: yup
    .string()
    .required('Template ID is required')
    .test(
      'templateId',
      'Template ID does not allow special characters',
      (value) => !hasSpecialCharacters(value),
    ),
  templateRequiredFields: yup
    .array()
    .required('Required Field JSON is required')
    .test('templateRequiredFields', 'Invalid', (inputValue) => {
      if (!inputValue) return false
      if (inputValue.length === 0) return false
      for (const { key, value } of inputValue) {
        if (!key || !value) return false
      }
      return true
    })
    .test('templateRequiredFields', 'Duplicated Keys', (inputValue) => {
      return !hasDuplicate(inputValue as { [key: string]: string }[], 'key')
    }),
  emailHeader: yup.string().required('EmailHeader is required'),
  emailBody: yup.string().required('EmailBody is required'),
  smsBody: yup.string().required('SMS Body is required'),
})

const initJson = [
  {
    key: 'firstName',
    value: '',
  },
]
export const SurveyMonkeyMasterForm = ({ onClose, refetch, open, surveyMonkeyMaster }: Props) => {
  const [json, setJson] = useState<{ key: string; value: string }[]>([
    { key: 'firstName', value: '' },
  ])
  const [createSurveyMonkeyMaster, { isLoading, isError, error, isSuccess }] =
    useCreateSurveyMonkeyMasterMutation()
  const [
    updateSurveyMonkey,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateSurveyMonkeyMasterMutation()

  const getJsonArray = (input: ISurveyMonkeyMaster | undefined) => {
    let parseJson: { [key: string]: string } = {}
    let parseJsonArray: { key: string; value: string }[] = []
    try {
      parseJson = input?.templateRequiredFields ? JSON.parse(input?.templateRequiredFields) : {}
      if (parseJson && Object.keys(parseJson).length > 0) {
        for (const key in parseJson) {
          parseJsonArray.push({
            key: key,
            value: parseJson[key],
          })
        }
      } else {
        parseJsonArray = [...initJson]
      }
    } catch (e) {
      console.log(e)
    }
    return [...parseJsonArray]
  }
  const formik = useFormik({
    initialValues: {
      kProgram: surveyMonkeyMaster?.kProgram ?? '',
      templateId: surveyMonkeyMaster?.templateId ?? '',
      templateRequiredFields: getJsonArray(surveyMonkeyMaster),
      emailBody: surveyMonkeyMaster?.emailBody ?? '',
      emailHeader: surveyMonkeyMaster?.emailHeader ?? '',
      smsBody: surveyMonkeyMaster?.smsBody ?? '',
      isEmailSendFlg: surveyMonkeyMaster?.isEmailSendFlg ?? 0,
      isSMSSendFlg: surveyMonkeyMaster?.isSMSSendFlg ?? 0,
    },
    validationSchema,
    onSubmit: (values) => {
      const jsonObj: { [key: string]: string } = {}
      for (let i = 0; i < json.length; i++) {
        const key = json[i].key
        const value = json[i].value
        jsonObj[key] = value
      }
      const requestData = {
        kProgram: values.kProgram,
        templateId: values?.templateId,
        templateRequiredFields: JSON.stringify(jsonObj),
        emailBody: values?.emailBody,
        emailHeader: values?.emailHeader,
        smsBody: values?.smsBody,
        isEmailSendFlg: values?.isEmailSendFlg,
        isSMSSendFlg: values?.isSMSSendFlg,
      }

      if (surveyMonkeyMaster?.surveyMasterId) {
        updateSurveyMonkey({
          surveyMasterId: surveyMonkeyMaster.surveyMasterId,
          ...requestData,
        })
      } else {
        createSurveyMonkeyMaster({
          ...requestData,
        })
      }
    },
  })

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  React.useEffect(() => {
    const parseJsonArray = getJsonArray(surveyMonkeyMaster)
    formik.setValues({
      kProgram: surveyMonkeyMaster?.kProgram ?? '',
      templateId: surveyMonkeyMaster?.templateId ?? '',
      templateRequiredFields: parseJsonArray,
      emailBody: surveyMonkeyMaster?.emailBody ?? '',
      emailHeader: surveyMonkeyMaster?.emailHeader ?? '',
      smsBody: surveyMonkeyMaster?.smsBody ?? '',
      isEmailSendFlg: surveyMonkeyMaster?.isEmailSendFlg ?? 0,
      isSMSSendFlg: surveyMonkeyMaster?.isSMSSendFlg ?? 0,
    })
    setJson([...parseJsonArray])
  }, [surveyMonkeyMaster])

  React.useEffect(() => {
    formik.setFieldValue('templateRequiredFields', [...json])
  }, [json])

  React.useEffect(() => {
    if (!open) {
      formik.resetForm()
      setJson([...initJson])
    }
  }, [open])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  const addJsonField = () => {
    setJson([
      ...json,
      {
        key: '',
        value: '',
      },
    ])
  }

  const deleteJsonField = (index: number) => {
    if (json[index].key === 'email' && formik.values.isEmailSendFlg) return
    if (json[index].key === 'phone' && formik.values.isSMSSendFlg) return
    const oldJson = [...json]
    oldJson.splice(index, 1)
    setJson([...oldJson])
  }

  const onChangeJson = (type: string, value: string, index: number) => {
    const oldJson = [...json]
    if (type === 'key') {
      oldJson[index].key = value
    } else {
      oldJson[index].value = value
    }
    setJson([...oldJson])
  }

  const handleChangeSurveyType = (type: string) => {
    const newIsEmailSendFlag = formik.values.isEmailSendFlg ? 0 : 1
    const newIsSMSSendFlag = formik.values.isSMSSendFlg ? 0 : 1
    if (type === 'email') {
      formik.setFieldValue('isEmailSendFlg', newIsEmailSendFlag)
    } else if (type === 'phone') {
      formik.setFieldValue('isSMSSendFlg', newIsSMSSendFlag)
    }
    let isJsonChangeRequired = false
    const newJson = [...json]
    if (
      type === 'email' &&
      newIsEmailSendFlag &&
      json.filter((item) => item.key === 'email').length === 0
    ) {
      isJsonChangeRequired = true
      newJson.push({
        key: 'email',
        value: '',
      })
    }
    if (
      type === 'phone' &&
      newIsSMSSendFlag &&
      json.filter((item) => item.key === 'phone').length === 0
    ) {
      isJsonChangeRequired = true
      newJson.push({
        key: 'phone',
        value: '',
      })
    }
    if (isJsonChangeRequired) {
      setJson(newJson)
    }
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Survey Monkey Master
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={1} direction={'row'}>
              <Item>
                <TextField
                  fullWidth
                  id='kProgram'
                  name='kProgram'
                  label='kustomer Program'
                  autoComplete='off'
                  value={formik.values.kProgram}
                  onChange={formik.handleChange}
                  error={formik.touched.kProgram && Boolean(formik.errors.kProgram)}
                  helperText={formik.touched.kProgram && formik.errors.kProgram}
                />
              </Item>
              <Item>
                <TextField
                  fullWidth
                  id='templateId'
                  name='templateId'
                  label='Template ID'
                  autoComplete='off'
                  value={formik.values.templateId}
                  onChange={formik.handleChange}
                  error={formik.touched.templateId && Boolean(formik.errors.templateId)}
                  helperText={formik.touched.templateId && formik.errors.templateId}
                />
              </Item>
            </Stack>
            <Stack spacing={1}>
              <Item>
                <Typography
                  sx={{
                    color:
                      formik.touched.templateRequiredFields &&
                      Boolean(formik.errors.templateRequiredFields)
                        ? '#d32f2f'
                        : '#8f8d8d',
                  }}
                >
                  Required Fields JSON
                </Typography>
                <Item sx={styles.jsonContainer}>
                  {json?.map((field, index) => (
                    <Grid container key={index} sx={{ mt: 0.3 }}>
                      <Grid item xs={5}>
                        <TextField
                          value={field.key}
                          size='small'
                          onChange={(e) => onChangeJson('key', e.target.value, index)}
                          error={formik.touched.templateRequiredFields && !field.key}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          value={field.value}
                          size='small'
                          onChange={(e) => onChangeJson('value', e.target.value, index)}
                          error={formik.touched.templateRequiredFields && !field.value}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton aria-label='add' onClick={() => deleteJsonField(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <IconButton aria-label='delete' onClick={addJsonField} sx={{ mt: 0.5 }}>
                    <AddIcon />
                  </IconButton>
                </Item>
                <Typography sx={{ color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px' }}>
                  {/* {formik.touched.templateRequiredFields && formik.errors.templateRequiredFields} */}
                </Typography>
              </Item>
            </Stack>
            <Stack spacing={1}>
              <Item>
                <Grid container style={{ alignItems: 'center' }}>
                  <Typography style={{ marginRight: '15px' }}>Send survey by:</Typography>
                  <FormControlLabel
                    onMouseDown={() => handleChangeSurveyType('email')}
                    control={
                      <Checkbox
                        id='isEmailSendFlg'
                        name='isEmailSendFlg'
                        checked={formik.values.isEmailSendFlg ? true : false}
                      />
                    }
                    label='Email'
                  />
                  <FormControlLabel
                    onMouseDown={() => handleChangeSurveyType('phone')}
                    control={
                      <Checkbox
                        id='isSMSSendFlg'
                        name='isSMSSendFlg'
                        checked={formik.values.isSMSSendFlg ? true : false}
                      />
                    }
                    label='SMS'
                  />
                </Grid>
              </Item>
            </Stack>
            <Stack spacing={1}>
              <Item>
                <TextField
                  fullWidth
                  id='emailHeader'
                  name='emailHeader'
                  label='Email Header'
                  autoComplete='off'
                  value={formik.values.emailHeader}
                  onChange={formik.handleChange}
                  error={formik.touched.emailHeader && Boolean(formik.errors.emailHeader)}
                  helperText={formik.touched.emailHeader && formik.errors.emailHeader}
                />
              </Item>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    id='emailBody'
                    name='emailBody'
                    label='Email Body'
                    autoComplete='off'
                    value={formik.values.emailBody}
                    onChange={formik.handleChange}
                    error={formik.touched.emailBody && Boolean(formik.errors.emailBody)}
                    helperText={formik.touched.emailBody && formik.errors.emailBody}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    id='smsBody'
                    name='smsBody'
                    label='SMS Body'
                    autoComplete='off'
                    value={formik.values.smsBody}
                    onChange={formik.handleChange}
                    error={formik.touched.smsBody && Boolean(formik.errors.smsBody)}
                    helperText={formik.touched.smsBody && formik.errors.smsBody}
                  />
                </Item>
              </Grid>
            </Grid>
            <Box sx={{ textAlign: 'right' }}>
              <Item>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{ mr: 2, borderRadius: '20px' }}
                  color='error'
                  onClick={reset}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  sx={{ borderRadius: '20px' }}
                  size='large'
                  type='submit'
                  disabled={isLoading || uIsLoading}
                >
                  Submit
                  {(isLoading || uIsLoading) && <CircularProgress size={20} />}
                </Button>
              </Item>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
