import React from 'react'
import { useAppSelector } from '../../redux/store'
import { isEmpty, lowerCase } from 'lodash'
import { Navigate } from 'react-router-dom'

export function HomePage() {
  const user = useAppSelector((state) => state.userState.user)

  if (isEmpty(user)) {
    return null
  }

  const electrolux = user.userRoleList?.filter((role) => {
    if (lowerCase(role.application) === 'electrolux' && role.isDefaultFlg) {
      return true
    }
    return false
  })

  if (!isEmpty(electrolux)) {
    return <Navigate to='/electrolux/home' replace />
  }

  return <div>Home Page</div>
}
