import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  ISurveyMonkeyMaster,
  ICreateSurveyMonkeyMasterRequest,
  IUpdateSurveyMonkeyMasterRequest,
  IUpdateSurveyMonkeyMasterStatusRequest,
  GenericResponse,
} from '../../types'
import { setSurveyMonkeyMaster } from '../slice/surveyMonkeySlice'

export const surveyMonkeyApi = createApi({
  reducerPath: 'surveyMonkeyApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['ISurveyMonkeyMaster'],
  endpoints: (builder) => ({
    getSurveyMonkeyMasterList: builder.query<ISurveyMonkeyMaster[], void>({
      query() {
        return {
          url: `surveymonkey/master/all`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { surveyMonkeyMaster: ISurveyMonkeyMaster[] } }) =>
        result.data.surveyMonkeyMaster,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setSurveyMonkeyMaster(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    createSurveyMonkeyMaster: builder.mutation<GenericResponse, ICreateSurveyMonkeyMasterRequest>({
      query(data) {
        return {
          url: 'surveymonkey/master/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateSurveyMonkeyMaster: builder.mutation<GenericResponse, IUpdateSurveyMonkeyMasterRequest>({
      query(data) {
        return {
          url: 'surveymonkey/master/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateSurveyMonkeyMasterStatus: builder.mutation<
      GenericResponse,
      IUpdateSurveyMonkeyMasterStatusRequest
    >({
      query(data) {
        return {
          url: 'surveymonkey/master/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetSurveyMonkeyMasterListQuery,
  useCreateSurveyMonkeyMasterMutation,
  useUpdateSurveyMonkeyMasterMutation,
  useUpdateSurveyMonkeyMasterStatusMutation,
} = surveyMonkeyApi
