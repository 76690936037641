/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, TextField, Stack, Box } from '@mui/material'
import LoginLogo from '../../../assets/images/LoginLogo.png'
import * as yup from 'yup'
import { useFormik } from 'formik'

const styles = {
  buttonStyle: {
    display: 'block',
    background: '#040F3E',
    fontWeight: 700,
    fontSize: '18px',
    borderRadius: '0px',
    textTransform: 'capitalize',
    width: '158px',
    ml: 'auto',
    mr: 'auto',
  },
}

interface Props {
  onContinue: (value?: string) => void
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
})

export const EmailInputForm = ({ onContinue }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.email.includes('@electrolux.com')) {
        localStorage.setItem('application', 'Electrolux')
        onContinue('Electrolux')
      } else {
        localStorage.setItem('application', 'Intranet')
        onContinue('Intranet')
      }
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px',
          flexDirection: 'column',
        }}
      >
        <img src={LoginLogo} alt='login logo' height='40' />
      </Box>
      <Stack spacing={3}>
        <TextField
          fullWidth
          id='email'
          name='email'
          placeholder='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Stack>
      <Button variant='contained' type='submit' sx={{ mt: 3, ...styles.buttonStyle }}>
        Continue
      </Button>
    </form>
  )
}
