import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Header } from '../../components/Layout/Header'
import { Container, Box, Typography, Button } from '@mui/material'

export const ServiceSuccess = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!location?.state?.id) {
      navigate('/bounce/scheduleService')
    }
  }, [])

  const date: any = useMemo(() => {
    if (!location?.state?.time) return ''
    const dates = location?.state?.time.split(' ')
    const d = dates[0]
    const t = dates[1]
    const dSplits = d.split('-')
    return {
      date: `${dSplits[1]}/${dSplits[2]}/${dSplits[0]}`,
      time: t,
    }
  }, [location?.state?.time])

  const back = () => {
    navigate('/bounce/scheduleService')
  }
  return (
    <>
      <Box>
        <Header />
        <Container maxWidth='md' sx={{ paddingTop: '100px' }}>
          <Typography variant='h4'>Your claim id is {location?.state?.id}.</Typography>
          <br />
          <br />
          <Typography variant='h6'>Your appointment has been scheduled for </Typography>
          <Typography variant='h6'>
            {date?.date} at {date?.time} with :{' '}
          </Typography>
          <Typography variant='h6'>{location?.state?.location?.short_name}</Typography>
          <Typography variant='h6'>{location?.state?.location?.address1}.</Typography>
          <Typography variant='h6'>
            {location?.state?.location?.state}, {location?.state?.location?.zip}
          </Typography>
          <Typography variant='h6'>{location?.state?.location?.country}</Typography>
          <br />
          <Button variant='contained' color='primary' onClick={back}>
            File Another Claim
          </Button>
        </Container>
      </Box>
    </>
  )
}
