import * as React from 'react'
import { useNavigate } from 'react-router'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import Button from '@mui/material/Button'

interface Props {
  admins: (string | undefined)[]
}

export function Reports({ admins }: Props) {
  const navigate = useNavigate()
  const [anchorElReport, setAnchorElReport] = React.useState<null | HTMLElement>(null)

  const handleOpenReportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElReport(event.currentTarget)
  }

  const handleCloseReportMenu = () => {
    setAnchorElReport(null)
  }

  const goToIncomplete = () => {
    navigate('/reports/incompletesolicitations')
    handleCloseReportMenu()
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleOpenReportMenu}
        sx={{ color: 'white', textDecorationLine: 'none' }}
        startIcon={<ContentPasteOutlinedIcon />}
        endIcon={anchorElReport ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        Reports
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElReport}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElReport)}
        onClose={handleCloseReportMenu}
      >
        {admins.includes('intranet') && (
          <MenuItem onClick={goToIncomplete}>
            <ShowChartIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Incomplete Solicitations</Typography>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}
