import { Stack, Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ServicerCard } from './ServicerCard'
import GoogleMap from './GoogleMap'
import makeStyles from '@mui/styles/makeStyles'
import Img from '../../assets/images/bounce.png'
import { BLocation } from '../../types'
import { isEmpty } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '6px',
    marginTop: '10px',
    padding: '30px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  map: {
    flex: 2,
    height: '520px',
  },
  content: {
    flex: 1.5,
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    gap: '10px',
    overflowY: 'auto',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  // iconBox: {

  // },
}))

interface Props {
  setActiveStep: (step: number) => void
  locations: BLocation[]
  selectLocation: (location: BLocation) => void
}

export const FindServicer = ({ setActiveStep, locations, selectLocation }: Props) => {
  const nonMobile = useMediaQuery('(min-width:600px)')
  const [activeIndex, setActiveIndex] = useState(0)
  const classes = useStyles()

  if (isEmpty(locations)) {
    setActiveStep(0)
  }

  return (
    <Box className={classes.container}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Box className={classes.map}>
          <GoogleMap
            locations={locations}
            setActiveIndex={(index: number) => setActiveIndex(index)}
          />
          {nonMobile && (
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              justifyContent='center'
              sx={{ marginTop: '10px' }}
            >
              <img src={Img} alt='boune img' className='bounce-img-card' />
              <Typography sx={{ fontWeight: 700 }}>Preferred Servicer</Typography>
            </Stack>
          )}
        </Box>
        <Box className={classes.content}>
          <Typography sx={{ fontWeight: 700, textAlign: 'center', fontSize: '24px' }}>
            Choose a Servicer
          </Typography>
          {locations?.map((location, index) => (
            <ServicerCard
              key={location.global_id}
              selected={index === activeIndex}
              location={location}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </Box>
      </Stack>
      {!nonMobile && (
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          justifyContent='center'
          sx={{ marginTop: '10px' }}
        >
          <img src={Img} alt='boune img' className='bounce-img-card' />
          <Typography sx={{ fontWeight: 700 }}>Preferred Servicer</Typography>
        </Stack>
      )}
      <Box className={classes.buttons}>
        <Stack direction='row' spacing={2}>
          <Button variant='contained' onClick={() => setActiveStep(0)}>
            Back
          </Button>
          <Button variant='outlined' onClick={() => selectLocation(locations[activeIndex])}>
            Next
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
