import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import EditIcon from '@mui/icons-material/Edit'
import { IDispatchMessageMaster } from '../../types'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material'
import { useUpdateDispatchMessageStatusMutation } from '../../redux/api/dispatchMessageMasterApi'
import { useAppSelector } from '../../redux/store'

const styles = {
  container: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  actionsContainer: {
    display: 'flex',
    //justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  actionButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#115293',
    },
    // '&:disabled': {
    //   backgroundColor: '#b3e5fc',
    //   cursor: 'not-allowed',
    // },
  },
  modalButton: {
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof IDispatchMessageMaster>(
  order: Order,
  orderBy: Key,
): (a: IDispatchMessageMaster, b: IDispatchMessageMaster) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  id: keyof IDispatchMessageMaster
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'isActiveFlg',
    label: 'Status',
  },
  {
    id: 'brandCode',
    label: 'Brand',
  },
  {
    id: 'model',
    label: 'Model',
  },
  {
    id: 'message',
    label: 'Message',
  },
]

interface DispatchMessageTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IDispatchMessageMaster) => void
  order: Order
  orderBy: string
  numSelected: number
  rowCount: number
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function UserTableHead(props: DispatchMessageTableHeadProps) {
  const { order, orderBy, onRequestSort, numSelected, rowCount, onSelectAll } = props
  const createSortHandler =
    (property: keyof IDispatchMessageMaster) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'select all displayed rows' }}
          />
        </TableCell>
        <TableCell padding='checkbox'></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props {
  isLoading: boolean
  rows: IDispatchMessageMaster[]
  searchKey: string
  editDispatchMessage: (data: IDispatchMessageMaster) => void
  refetch: () => void
}

export const DispatchMessageTable = ({
  isLoading,
  rows,
  searchKey,
  editDispatchMessage,
  refetch,
}: Props) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof IDispatchMessageMaster>('dispatchMessageId')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState<string>('')
  const user = useAppSelector((state) => state.userState.user)
  const [
    updateDispatchMessageStatus,
    {
      isLoading: statusUpdtIsLoading,
      // isError: statusUpdtIsError,
      // error: statusUpdtError,
      // isSuccess: statusUpdtIsSuccess,
    },
  ] = useUpdateDispatchMessageStatusMutation()
  const actionIsLoading = useMemo(() => statusUpdtIsLoading, [statusUpdtIsLoading])
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IDispatchMessageMaster,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelect = (id: number) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(displayedRows.map((row) => row.dispatchMessageId))
    } else {
      setSelected([])
    }
  }

  const handleAction = (actionType: string) => {
    setAction(actionType)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setAction('')
  }

  const handleConfirm = async () => {
    switch (action) {
      case 'activate':
        // Call the activate function
        await updateDispatchMessageStatus({
          dispatchMessageIdList: selected,
          isActiveFlgStatus: 1,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
        })
        break
      case 'deactivate':
        // Call the deactivate function
        await updateDispatchMessageStatus({
          dispatchMessageIdList: selected,
          isActiveFlgStatus: 0,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
        })
        break
      default:
        break
    }
    setSelected([])
    handleClose()
    refetch()
  }

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchKey.toLowerCase()),
    ),
  )

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy))
  const displayedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0

  if (isLoading) {
    return (
      <Box sx={styles.container}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={styles.actionsContainer}>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={selected.length === 0}
          onClick={() => handleAction('activate')}
        >
          Activate
        </Button>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={selected.length === 0}
          onClick={() => handleAction('deactivate')}
        >
          Deactivate
        </Button>
      </Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table aria-labelledby='tableTitle' size='medium'>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAll}
              numSelected={selected.length}
              rowCount={displayedRows.length}
            />
            <TableBody>
              {displayedRows.map((row, index) => {
                const isItemSelected = selected.includes(row.dispatchMessageId)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={() => handleSelect(row.dispatchMessageId)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.dispatchMessageId}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell padding='checkbox'>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          editDispatchMessage(row)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component='th' id={labelId} scope='row'>
                      {row?.isActiveFlg ? (
                        <Chip label='Active' color='primary' size='small' />
                      ) : (
                        <Chip label='Inactive' color='error' size='small' />
                      )}
                    </TableCell>
                    <TableCell>{row.brandCode}</TableCell>
                    <TableCell>{row.model}</TableCell>
                    <TableCell>{row.message}</TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 40]}
          component='div'
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={open} onClose={handleClose} aria-describedby='alert-dialog-description'>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {action} the selected items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={styles.modalButton}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} sx={styles.modalButton} autoFocus>
            Confirm
            {actionIsLoading ? <CircularProgress size={24} style={{ marginLeft: '5px' }} /> : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
