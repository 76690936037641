import { createApi } from '@reduxjs/toolkit/query/react'
import customElxFetchBase from './customElxFetchBase'
import {
  IElxData,
  GenericResponse,
  IUpdateElxDataStatusRequest,
  IUpdateElxDataRequest,
  ICreateElxDataRequest,
  ActiveFlg,
} from '../../types'
import { setDateTriggerType } from '../slice/dateTriggerTypeSlice'

export const dateTriggerTypeApi = createApi({
  reducerPath: 'dateTriggerTypeApi',
  baseQuery: customElxFetchBase,
  tagTypes: ['DateTriggerType'],
  endpoints: (builder) => ({
    getDateTriggerType: builder.mutation<IElxData, string>({
      query(dateTriggerType) {
        return {
          url: `datetriggertypes/get/${dateTriggerType}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IElxData }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setDateTriggerType(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getDateTriggerTypeList: builder.query<IElxData[], ActiveFlg | null>({
      query(data) {
        return {
          url: `datetriggertypes/get_list/${data ? 'active' : 'all'}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { dateTriggerTypeList: IElxData[] } }) =>
        result.data.dateTriggerTypeList,
    }),
    createDateTriggerType: builder.mutation<GenericResponse, ICreateElxDataRequest>({
      query(data) {
        return {
          url: 'datetriggertypes/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateDateTriggerType: builder.mutation<GenericResponse, IUpdateElxDataRequest>({
      query(data) {
        return {
          url: 'datetriggertypes/update',
          method: 'POST',
          body: data,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateDateTriggerTypeStatus: builder.mutation<GenericResponse, IUpdateElxDataStatusRequest>({
      query(data) {
        return {
          url: 'datetriggertypes/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetDateTriggerTypeMutation,
  useGetDateTriggerTypeListQuery,
  useCreateDateTriggerTypeMutation,
  useUpdateDateTriggerTypeMutation,
  useUpdateDateTriggerTypeStatusMutation,
} = dateTriggerTypeApi
