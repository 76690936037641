import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material'
import React from 'react'
import { FormikProps } from 'formik'

interface FormValues {
  email: string
  firstName: string
  lastName: string
  phone: string
  dealerGroupNumber: string[]
}
interface Props {
  openAddDealerGroup: boolean
  onCloseAddDealerGroup: () => void
  formik: FormikProps<FormValues>
  dealerGroupNumberList: { dealerGroupName: string; dealerGroupNumber: string }[]
}

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: 'white',
    mb: 4,
  },
  selectMenu: {
    maxHeight: 200, // Adjust as needed
    marginTop: '8px', // Adjust as needed
  },
  formControlStyles: {
    width: '90%',
    margin: 'auto',
  },
}

function UserDealerGroupAdd(props: Props) {
  const { openAddDealerGroup, onCloseAddDealerGroup, formik, dealerGroupNumberList } = props
  return (
    <Modal
      open={openAddDealerGroup}
      onClose={onCloseAddDealerGroup}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='black'>
            Dealer Groups
          </Typography>
        </Box>
        <Grid xs={12}>
          <Grid item sx={styles.formControlStyles}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  backgroundColor: 'white',
                  paddingLeft: 1,
                  paddingRight: 1,
                  '&.Mui-focused': {
                    color: 'black',
                  },
                }}
                id='dealerGroupNumber'
              >
                Dealer Group Name
              </InputLabel>
              <Select
                labelId='dealerGroupNumber'
                id='dealerGroupNumber'
                name='dealerGroupNumber'
                multiple
                value={formik.values.dealerGroupNumber} // Should be an array in Formik state
                onChange={(event) => {
                  formik.setFieldValue('dealerGroupNumber', event.target.value as string[])
                }}
                renderValue={(selected) =>
                  Array.isArray(selected)
                    ? selected
                        .map((value) => {
                          const group = dealerGroupNumberList.find(
                            (group) => group.dealerGroupNumber === value,
                          )
                          return group ? group.dealerGroupName : value
                        })
                        .join(', ')
                    : ''
                }
                MenuProps={{
                  PaperProps: {
                    style: styles.selectMenu,
                  },
                }}
              >
                {Array.isArray(dealerGroupNumberList) &&
                  dealerGroupNumberList?.map((group) => (
                    <MenuItem key={group.dealerGroupNumber} value={group.dealerGroupNumber}>
                      {group.dealerGroupName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'right', mt: '30px', mb: '30px', mr: '20px' }}>
          <Button
            variant='outlined'
            size='large'
            sx={{ mr: 2, borderRadius: '20px' }}
            color='error'
            onClick={onCloseAddDealerGroup}
          >
            Cancel
          </Button>
          <Button
            onClick={onCloseAddDealerGroup}
            variant='contained'
            sx={{ borderRadius: '20px' }}
            size='large'
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UserDealerGroupAdd
