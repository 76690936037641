import { createApi } from '@reduxjs/toolkit/query/react'
import customElxFetchBase from './customElxFetchBase'
import {
  IElxData,
  GenericResponse,
  IUpdateElxDataStatusRequest,
  IUpdateElxDataRequest,
  ICreateElxDataRequest,
  ActiveFlg,
} from '../../types'
import { setMarketingType } from '../slice/marketingTypeSlice'

export const marketingTypeApi = createApi({
  reducerPath: 'marketingTypeApi',
  baseQuery: customElxFetchBase,
  tagTypes: ['MarketingType'],
  endpoints: (builder) => ({
    getMarketingType: builder.mutation<IElxData, string>({
      query(marketingType) {
        return {
          url: `marketingTypes/get/${marketingType}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IElxData }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setMarketingType(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getMarketingTypeList: builder.query<IElxData[], ActiveFlg | null>({
      query(data) {
        return {
          url: `marketingtypes/get_list/${data ? 'active' : 'all'}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { marketingTypeList: IElxData[] } }) =>
        result.data.marketingTypeList,
    }),
    createMarketingType: builder.mutation<GenericResponse, ICreateElxDataRequest>({
      query(data) {
        return {
          url: 'marketingtypes/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateMarketingType: builder.mutation<GenericResponse, IUpdateElxDataRequest>({
      query(data) {
        return {
          url: 'marketingtypes/update',
          method: 'POST',
          body: data,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateMarketingTypeStatus: builder.mutation<GenericResponse, IUpdateElxDataStatusRequest>({
      query(data) {
        return {
          url: 'marketingtypes/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetMarketingTypeMutation,
  useGetMarketingTypeListQuery,
  useCreateMarketingTypeMutation,
  useUpdateMarketingTypeMutation,
  useUpdateMarketingTypeStatusMutation,
} = marketingTypeApi
