import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { useGetDispatchMessageQuery } from '../../redux/api/dispatchMessageMasterApi'
import { DispatchMessageForm } from './DispatchMessageForm'
import { IDispatchMessageMaster } from '../../types'
import { DispatchMessageTable } from '../../components/Table/DispatchMessageTable'

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
}

export const DispatchMessage = () => {
  const { data, isLoading: queryLoading, isError, refetch } = useGetDispatchMessageQuery()
  const [selectedMessage, setSelectedMessage] = useState<IDispatchMessageMaster | undefined>(
    undefined,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [isLoading, setIsLoading] = useState(queryLoading)
  useEffect(() => setIsLoading(queryLoading), [queryLoading])
  const handleOpenModal = (message?: IDispatchMessageMaster) => {
    setSelectedMessage(message)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setSelectedMessage(undefined)
    setIsModalOpen(false)
  }

  const handleRefetch = async () => {
    setIsLoading(true)
    await refetch()
    setIsLoading(false)
  }
  if (isError) {
    return (
      <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
        <Typography color='error'>Failed to load dispatch messages</Typography>
      </Container>
    )
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Dispatch Messages
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px' }}
            startIcon={<AddIcon />}
            onClick={() => {
              handleOpenModal()
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <DispatchMessageTable
        isLoading={isLoading}
        rows={data || []}
        searchKey={searchKey}
        editDispatchMessage={handleOpenModal}
        refetch={handleRefetch}
      />
      {isModalOpen && (
        <DispatchMessageForm
          open={isModalOpen}
          onClose={handleCloseModal}
          refetch={handleRefetch}
          dispatchMessageMaster={selectedMessage}
        />
      )}
    </Container>
  )
}
