import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IElxData } from '../../types'

interface ITriggerFactorState {
  triggerFactor: IElxData | null
}

const initialState: ITriggerFactorState = {
  triggerFactor: null,
}

export const triggerFactorSlice = createSlice({
  initialState,
  name: 'triggerFactorSlice',
  reducers: {
    setTriggerFactor: (state, action: PayloadAction<IElxData>) => {
      state.triggerFactor = action.payload
    },
  },
})

export default triggerFactorSlice.reducer

export const { setTriggerFactor } = triggerFactorSlice.actions
