import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IUserState {
  accessToken?: string
}

const initialState: IUserState = {
  accessToken: undefined,
}

export const authSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    reset: () => initialState,
    setToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
  },
})

export default authSlice.reducer

export const { reset, setToken } = authSlice.actions
