/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { solicitationApi } from '../../redux/api/solicitationApi'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import { Refresh } from '@mui/icons-material'
import { IPerson, ISolicitation } from '../../types'
import { isEmpty, startCase, toLower } from 'lodash'
import { YearDetails } from './YearDetails'
import moment from 'moment'

interface Props {
  row: ISolicitation
  currentIndex: number
}

export const ExpandableRow = ({ row: rowProps, currentIndex }: Props) => {
  const [row, setRow] = useState(rowProps)
  const [open, setIsOpen] = useState(false)
  const [trigger, { data, isLoading, isFetching, isSuccess }] =
    solicitationApi.endpoints.getSolicitationById.useLazyQuery()
  const loading = isLoading || isFetching
  const getStatus = (data: ISolicitation) => {
    if (data?.isPaidFlg === 1) {
      return <Chip label='Purchased' color='success' size='small' />
    }
    if (data?.beginDt && data?.endDt) {
      const today = moment()
      const startDate = moment(data.beginDt)
      const endDate = moment(data.endDt)
      if (startDate.isBefore(today) && today.isBefore(endDate)) {
        return <Chip label='Active' color='info' size='small' />
      }
      if (today.isAfter(endDate)) {
        return <Chip label='Expired' color='error' variant='outlined' size='small' />
      }
      if (today.isBefore(startDate)) {
        return <Chip label='Upcoming' color='info' variant='outlined' size='small' />
      }
      return null
    } else {
      return <Chip label='Active' color='info' size='small' />
    }
  }

  const handleOnClick = () => {
    setIsOpen(!open)
  }

  const handleRefresh = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    trigger(row?.solicitationMasterId)
  }

  useEffect(() => {
    if (!loading && isSuccess && data) {
      setRow({
        ...data,
        otherSolicitation: row?.otherSolicitation,
      })
    }
  }, [loading])

  const offerType = row.isPaidFlg === 1 ? 'Purchased' : row?.leadType

  return (
    <>
      <TableRow
        onClick={handleOnClick}
        sx={{
          backgroundColor: currentIndex % 2 === 0 ? '#F8F9FA' : 'white',
          cursor: row.isPaidFlg === 1 ? 'default' : 'pointer',
        }}
      >
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          <IconButton onClick={handleRefresh}>
            {loading ? <CircularProgress size={20} /> : <Refresh />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {getStatus(row)}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row?.endDt ? moment(row.endDt).format('L') : moment().format('L')}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.shortCode ?? ''}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {getFullName(row.person)}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.person?.address1 ?? row.person?.address2}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.person?.city ?? ''}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.person?.state ?? ''}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.person?.postalCode ?? ''}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.model ?? ''}
        </StyledTableCell>
        <StyledTableCell
          align='left'
          sx={{ borderBottomWidth: isEmpty(row.otherSolicitation) ? '1px' : '0px' }}
        >
          {row.serial ?? ''}
        </StyledTableCell>
      </TableRow>
      {!isEmpty(row.otherSolicitation) &&
        row.otherSolicitation?.map((item, index) => (
          <TableRow
            key={`${item.serial}${item.model}`}
            onClick={handleOnClick}
            sx={{
              backgroundColor: currentIndex % 2 === 0 ? '#F8F9FA' : 'white',
              cursor: row.isPaidFlg === 1 ? 'default' : 'pointer',
            }}
          >
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            ></StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            >
              {item.model ?? ''}
            </StyledTableCell>
            <StyledTableCell
              sx={{
                // @ts-ignore
                borderBottomWidth: row.otherSolicitation?.length - 1 === index ? '1px' : '0px',
              }}
            >
              {item.serial ?? ''}
            </StyledTableCell>
          </TableRow>
        ))}
      {open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <YearDetails
              detailId={row.solicitationDetailId}
              opw_offer_id={row.shortCode}
              solicitationNumber={row.solicitationNumber}
              isPaidFlg={row.isPaidFlg}
              elxContractNumber={row.elxContractNumber}
              brand={row.brand}
              endDt={row.endDt}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

function getFullName(person?: IPerson) {
  if (isEmpty(person)) {
    return ''
  }
  const fullName = `${person.firstName ?? ''} ${person.lastName ?? ''}`
  return startCase(toLower(fullName))
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontFamily: 'Poppins',
    color: '#000',
  },
}))

const headerStyle = {
  fontSize: 16,
  fontFamily: 'Poppins',
  color: '#000',
  fontWeight: 600,
}
