import { Box, Stack, Typography, Button } from '@mui/material'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { DateList } from './DateList'
import { BLocation } from '../../types'

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#dedede',
    borderRadius: '6px',
    marginTop: '10px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
  },
  buttons: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    height: '70px',
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dedede',
  },
}))

export interface Time {
  origin: string
  local: string
}

interface Times {
  [key: string]: Time[]
}

interface Props {
  location?: BLocation
  setActiveStep: (step: number) => void
  setTime: (time?: string) => void
}

export const ScheduleAppointment = ({ setActiveStep, location, setTime }: Props) => {
  const classes = useStyles()
  const [selectedTime, setSelectedTime] = useState<Time>()
  const timezone = location?.timezone ?? ''
  const available_appts = location?.available_appts ?? []
  const appts: Times = {}
  available_appts.map((time: string) => {
    const date = moment(time).tz(timezone).format('MMM DD')
    const value = moment(time).tz(timezone).format()
    if (appts[date]) {
      appts[date].push({ origin: time, local: value })
    } else {
      appts[date] = [{ origin: time, local: value }]
    }
  })

  return (
    <Box>
      <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
        <EventRepeatIcon sx={{ color: '#1ba1fa' }} />
        <Typography variant='h5' component='h5'>
          Service Time
        </Typography>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} className={classes.content}>
        {Object.keys(appts).map((key) => (
          <DateList
            key={key}
            date={key}
            day={moment(key).tz(timezone).format('ddd')}
            slots={appts[key]}
            selectedTime={selectedTime}
            onSelect={(time: Time) => setSelectedTime({ ...time })}
          />
        ))}
      </Stack>
      <Box className={classes.buttons}>
        <Stack direction='row' spacing={2}>
          <Button variant='contained' onClick={() => setActiveStep(1)}>
            Back
          </Button>
          <Button
            disabled={!selectedTime}
            variant='outlined'
            onClick={() => setTime(selectedTime?.origin)}
          >
            Next
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
