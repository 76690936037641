/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import { ISolicitationPurchaseIncomplete } from '../../types/reports'
import { capitalize } from 'lodash'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = typeof a[orderBy] === 'string' ? String(a[orderBy]).toLowerCase() : a[orderBy]
  const bValue = typeof b[orderBy] === 'string' ? String(b[orderBy]).toLowerCase() : b[orderBy]

  if (bValue < aValue) {
    return -1
  }
  if (bValue > aValue) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  id: keyof ISolicitationPurchaseIncomplete
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'daysSinceFailure',
    label: 'Days Since Exception',
  },
  {
    id: 'solicitationNumber',
    label: 'Invitation Id',
  },
  {
    id: 'createdOn',
    label: 'Date',
  },
  {
    id: 'errorMessage',
    label: 'Exception',
  },
]

interface SolicitationPurchaseIncompleteTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ISolicitationPurchaseIncomplete,
  ) => void
  order: Order
  orderBy: string
}

function SolicitationPurchaseIncompleteTableHead(
  props: SolicitationPurchaseIncompleteTableHeadProps,
) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof ISolicitationPurchaseIncomplete) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props {
  rows: ISolicitationPurchaseIncomplete[]
  searchKey?: string
}

export const SolicitationPurchaseIncompleteTable = ({ rows, searchKey = '' }: Props) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] =
    React.useState<keyof ISolicitationPurchaseIncomplete>('daysSinceFailure')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ISolicitationPurchaseIncomplete,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='solicitationPurchaseIncompleteTable'>
            <SolicitationPurchaseIncompleteTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* @ts-ignore */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((solicitation) =>
                  String(solicitation[orderBy])
                    .toLocaleLowerCase()
                    .includes(searchKey.toLocaleLowerCase()),
                )
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={`${row.solicitationNumber}_${index}`}
                    >
                      <TableCell>{capitalize(row?.daysSinceFailure as string)}</TableCell>
                      <TableCell>{capitalize(row?.solicitationNumber as string)}</TableCell>
                      <TableCell>{moment(row?.createdOn).format('L')}</TableCell>
                      <TableCell>{row?.errorMessage as string}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
