/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Container } from '@mui/material'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddressDetails } from '../../components/Bounce/AddressDetails'
import { EquipmentDetails } from '../../components/Bounce/EquipmentDetails'
import { FindServicer } from '../../components/Bounce/FindServicer'
import { ScheduleAppointment } from '../../components/Bounce/ScheduleAppointment'
import { Header } from '../../components/Layout/Header'
import { ScheduleStepper } from '../../components/ScheduleStepper/ScheduleStepper'
import { useCreateClaimMutation, useLazyGetLocationsQuery } from '../../redux/api/bounceApi'
import { BLocation, CustomerAddress, IDevice, IEquipment } from '../../types'

const steps = ['Address Details', 'Find Servicer', 'Schedule Appointment', 'Device Details']

export const ScheduleService = () => {
  const [createClaim, { isLoading, isError, error, isSuccess }] = useCreateClaimMutation()
  const [locations, setLocations] = useState<BLocation[]>([])
  const [getLocations] = useLazyGetLocationsQuery()
  const [activeStep, setActiveStep] = React.useState(0)
  const [activeLocation, setActiveLocation] = React.useState<BLocation>()
  const [selectedTime, setSelectedTime] = useState('')
  const [customer, setCustomer] = useState<CustomerAddress>()
  const [devices, setDevices] = useState<IDevice[]>([])
  const [claimPreLoading, setClaimPreLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  const handleGetToken = async () => {
    const url = process.env.REACT_APP_BOUNCE_ACCOUNTS_AUTHORITY_URL + '/connect/token'
    const data: any = {
      grant_type: 'client_credentials',
      client_id: 'bounce_mgmt_backend',
      client_secret: 'secret',
    }
    const formData = new URLSearchParams()
    for (const key in data) {
      formData.append(key, data[key])
    }
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
    const jsonRes = await res.json()
    return jsonRes
  }

  const handleGetDevices = async (email: string) => {
    const tokenRes = await handleGetToken()
    const url =
      process.env.REACT_APP_BOUNCE_BASE_API_URL +
      '/reamaze/customer/report?email=' +
      email +
      '&report_mode=Protection&output_format=json'
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenRes.access_token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setDevices(
          res.map((d: any) => ({
            publicId: d?.bounce_protection_public_id,
            imei: d?.imei,
          })),
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCustomerAddress = async (address: CustomerAddress) => {
    try {
      toast.dismiss('non-servicer')
      setLoading(true)
      setCustomer(address)
      handleGetDevices(address?.email)
      const response = await getLocations(`${address.address1}, ${address.city} ${address.state}`)
      if (isEmpty(response.data?.location)) {
        toast.error('No servicer found for the given address', {
          toastId: 'non-servicer',
          position: 'top-right',
          autoClose: false,
          type: 'error',
          bodyClassName: 'alert-body-color',
        })
      } else {
        // @ts-ignore
        setLocations(response.data.location)
        setActiveStep(1)
      }
      setLoading(false)
    } catch (error) {
      toast.error('Something Failed. Please try again!', {
        position: 'top-right',
      })
      setLoading(false)
    }
  }

  const selectLocation = (location: BLocation) => {
    setActiveLocation(location)
    setActiveStep(2)
  }

  const setTime = (time?: string) => {
    if (time) {
      setSelectedTime(time)
      setActiveStep(3)
    }
  }

  const checkSerial = (equipment: IEquipment) => {
    let result = null
    for (const d of devices) {
      if (d.imei === equipment.serial) {
        result = d.publicId
        break
      }
    }
    return result
  }

  const onCreateClaim = async (equipment: IEquipment, publicId: string) => {
    const dates = selectedTime.split(' ')
    if (customer && dates.length == 2) {
      setClaimPreLoading(true)
      const tokenRes = await handleGetToken()
      const url =
        process.env.REACT_APP_BOUNCE_BASE_API_URL + '/protections/' + publicId + '/servicerclaim'
      const data = {
        BreakStory: 'Servicer Claim Test',
      }
      fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenRes.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(async (res) => {
          setClaimPreLoading(false)
          if (res?.hasErrorFlg) {
            toast.error(res?.message, {
              toastId: 'elaim-error',
              position: 'top-right',
              autoClose: false,
              type: 'error',
              bodyClassName: 'alert-body-color',
            })
            return
          } else if (res?.onpointClaimId) {
            const createClaimRes = await createClaim({
              type: 'claim',
              customer,
              location_id: activeLocation?.gid ?? '',
              claim: {
                id: res.onpointClaimId,
                type: 'None',
                reason: 'CrackedScreen',
              },
              equipment,
              appointment_date: dates[0],
              appointment_time: dates[1],
              condition: ['CrackedScreen'],
            })
            navigate('/bounce/scheduleService/success', {
              state: {
                id: res?.onpointClaimId,
                time: selectedTime,
                location: activeLocation,
              },
            })
          }
        })
        .catch((err) => {
          console.log(err)
          setClaimPreLoading(false)
        })
    }
  }
  const onSubmit = (equipment: IEquipment) => {
    const dates = selectedTime.split(' ')
    if (customer && dates.length == 2) {
      const publicId = checkSerial(equipment)
      if (!publicId) {
        toast.error('No device found', {
          toastId: 'non-device',
          position: 'top-right',
          autoClose: false,
          type: 'error',
          bodyClassName: 'alert-body-color',
        })
        return
      } else {
        onCreateClaim(equipment, publicId)
      }
    }
  }

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return <AddressDetails setCustomerAddress={handleCustomerAddress} loading={loading} />
      case 1:
        return (
          <FindServicer
            setActiveStep={setActiveStep}
            locations={locations}
            selectLocation={selectLocation}
          />
        )
      case 2:
        return (
          <ScheduleAppointment
            setActiveStep={setActiveStep}
            location={activeLocation}
            setTime={setTime}
          />
        )
      case 3:
        return (
          <EquipmentDetails
            onSubmit={onSubmit}
            setActiveStep={setActiveStep}
            loading={isLoading || claimPreLoading}
          />
        )
      default:
        return null
    }
  }

  return (
    <Box>
      <Header />
      <Container maxWidth='md' sx={{ paddingTop: '40px' }}>
        <ScheduleStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      </Container>
      <Container maxWidth='lg' sx={{ marginTop: '40px', marginBottom: '40px' }}>
        {renderContent()}
      </Container>
    </Box>
  )
}
