import React from 'react'
import { Box, Typography } from '@mui/material'

const styles = {
  container: {
    border: '1px solid #DFE2E6',
    borderRadius: '5px',
    padding: '16px 20px',
    marginTop: '8px',
  },
  label: {
    fontFamily: 'Electrolux Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'capitalize',
    color: '#AEB5BC',
  },
}

interface EinputProps {
  label: string
  value: string
  placeholder: string
  onChange: (value: string) => void
}

export const EInput = ({ label, value, onChange, placeholder }: EinputProps) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <input
        className='electrolux-input'
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </Box>
  )
}
