import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IProductStatus,
  GenericResponse,
  IUpdateProductStatusStatusRequest,
  IUpdateProductStatusRequest,
  ICreateProductStatusRequest,
  ActiveFlg,
} from '../../types'

export const productStatusApi = createApi({
  reducerPath: 'productStatusApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['ProductStatus'],
  endpoints: (builder) => ({
    getProductStatusList: builder.query<IProductStatus[], ActiveFlg | null>({
      query(data) {
        return {
          url: `productstatuses/get_list/${data ? 'active' : 'all'}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { productStatusList: IProductStatus[] } }) =>
        result.data.productStatusList,
    }),
    createProductStatus: builder.mutation<GenericResponse, ICreateProductStatusRequest>({
      query(data) {
        return {
          url: 'productstatuses/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateProductStatus: builder.mutation<GenericResponse, IUpdateProductStatusRequest>({
      query(data) {
        return {
          url: 'productstatuses/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateProductStatusStatus: builder.mutation<GenericResponse, IUpdateProductStatusStatusRequest>(
      {
        query(data) {
          return {
            url: 'productstatuses/update_status',
            method: 'POST',
            body: data,
            credentials: 'include',
          }
        },
      },
    ),
  }),
})

export const {
  useGetProductStatusListQuery,
  useCreateProductStatusMutation,
  useUpdateProductStatusMutation,
  useUpdateProductStatusStatusMutation,
} = productStatusApi
