import React from 'react'
import { Grid, Paper } from '@mui/material'
import { ResetPasswordForm } from './ResetPasswordForm'

export function ResetPassword() {
  return (
    <Grid container direction='column' justifyContent='center' sx={{ minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item sx={{ width: 550 }}>
            <Paper sx={{ p: 3 }}>
              <ResetPasswordForm />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
