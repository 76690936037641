import React from 'react'
import { Box, Typography } from '@mui/material'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

interface Props {
  label: string
  value: string
  isTotal?: boolean
  isPaidFlg?: number
}

export const PlanValue = ({ label, value, isTotal, isPaidFlg }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <Typography sx={styles.label}>{label}</Typography>
      {isTotal || isPaidFlg === 1 ? (
        <Typography sx={styles.total}>${parseFloat(value).toFixed(2)}</Typography>
      ) : (
        <Box sx={styles.input}>
          <Typography sx={styles.inputValue}>${parseFloat(value).toFixed(2)}</Typography>
        </Box>
      )}
    </Box>
  )
}

interface InputProps {
  label: string
  value: string
  placeholder: string
  onChange: (value: string) => void
}
export const PlanInput = ({ label, value, placeholder, onChange }: InputProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <Typography sx={{ ...styles.label, width: '150px' }}>{label}</Typography>
      <input
        className='plan-input'
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </Box>
  )
}

export const PhoneNumberInput = ({ label, value, placeholder, onChange }: InputProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <Typography sx={{ ...styles.label, width: '150px' }}>{label}</Typography>
      <PhoneInput defaultCountry='US' value={value} placeholder={placeholder} onChange={onChange} />
    </Box>
  )
}

const styles = {
  input: {
    border: '1px solid #DFE2E6',
    borderRadius: '5px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    flex: 1,
  },
  inputValue: {
    fontSize: '15px',
    fontFamily: 'Poppins',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    width: '100px',
    textAlign: 'right',
    marginRight: '20px',
  },
  total: {
    fontWeight: 700,
    fontSize: '28px',
    fontFamily: 'Electrolux Sans',
  },
}
