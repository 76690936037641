/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Box,
  Divider,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMsal } from '@azure/msal-react'

import { useLoginUserMutation, useLoginMicrosoftMutation } from '../../../redux/api/authApi'
import { loginRequest } from '../../../config/msalConfig'
import { AnimateButton, FullScreenLoader } from '../../../components'
import LoginLogo from '../../../assets/images/LoginLogo.png'

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
})

const styles = {
  buttonStyle: {
    display: 'block',
    background: '#040F3E',
    fontWeight: 700,
    fontSize: '18px',
    borderRadius: '0px',
    textTransform: 'capitalize',
    width: '158px',
    ml: 'auto',
    mr: 'auto',
  },
  textButton: {
    ml: 'auto',
    mr: 'auto',
    display: 'block',
    color: '#040F3E',
    fontWeight: 400,
    fontSize: '15px',
  },
  orStyle: {
    cursor: 'unset',
    m: 2,
    py: 0.5,
    px: 7,
    fontWeight: 500,
    borderRadius: '3px',
  },
  button: {
    color: '#040F3E',
    fontWeight: 700,
    fontSize: '18px',
    textTransform: 'capitalize',
    textDecoration: 'underline',
    borderRadius: '0px',
    borderColor: '#040F3E',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    border: '1px solid #AEB5BC',
    borderRadius: '22px',
  },
  title: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#040F3E',
  },
}

interface Props {
  clickForgotPassword: () => void
  agentLogo?: React.ReactNode
  token: string | null
}

export const LoginForm = ({ clickForgotPassword, agentLogo, token }: Props) => {
  const { instance } = useMsal()
  const location = useLocation()
  const navigate = useNavigate()
  const ref = createRef()
  const [loginUser, { isLoading, isError, error, isSuccess }] = useLoginUserMutation()
  const [
    loginMicrosoft,
    { isLoading: loading, isError: mIsError, error: mError, isSuccess: mSuccess },
  ] = useLoginMicrosoftMutation()
  const [showPassword, setShowPassword] = React.useState(false)

  const from = ((location.state as any)?.from.pathname as string) || '/'

  React.useEffect(() => {
    if (isSuccess || mSuccess) {
      toast.success('You successfully logged in')
      navigate(from)
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
    if (mIsError) {
      if (Array.isArray((mError as any)?.data?.error)) {
        const errors = mError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((mError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading, loading])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const microsoftHandler = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        loginMicrosoft({ token: response?.accessToken, elxToken: token })
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginUser(values)
    },
  })

  if (loading && isLoading) {
    return <FullScreenLoader />
  }

  const fontFamily = agentLogo ? 'Electrolux Sans' : 'Roboto'

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px',
          flexDirection: agentLogo ? 'row' : 'column',
        }}
      >
        {agentLogo && <img src={agentLogo as string} alt='agent logo' height='40' />}
        <img src={LoginLogo} alt='login logo' height='40' />
      </Box>
      <Grid container direction='column' justifyContent='center' spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <AnimateButton ref={ref}>
              <Button
                disableElevation
                onClick={microsoftHandler}
                size='large'
                variant='outlined'
                sx={{ ...styles.button, fontFamily }}
              >
                Sign in with Microsoft
              </Button>
            </AnimateButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Divider sx={{ flexGrow: 1 }} orientation='horizontal' />
            <Box sx={styles.boxContainer}>
              <Typography sx={{ fontWeight: 700, fontSize: 16, fontFamily }}>OR</Typography>
            </Box>
            <Divider sx={{ flexGrow: 1 }} orientation='horizontal' />
          </Box>
        </Grid>
        <Grid item xs={12} container alignItems='center' justifyContent='center'>
          <Box sx={{ mb: 2 }}>
            <Typography style={{ ...styles.title, fontFamily }}>
              Sign in with Email address
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Stack spacing={3}>
        <TextField
          fullWidth
          id='email'
          name='email'
          placeholder='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          style={{ fontFamily }}
        />
        <TextField
          fullWidth
          id='password'
          name='password'
          placeholder='Password'
          type={!showPassword ? 'password' : 'text'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          style={{ fontFamily }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Button variant='contained' type='submit' sx={{ mt: 3, fontFamily, ...styles.buttonStyle }}>
        Login
      </Button>
      <Button
        variant='text'
        sx={{ mt: 2, fontFamily, ...styles.textButton }}
        onClick={clickForgotPassword}
      >
        Forgot Password?
      </Button>
    </form>
  )
}
