/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import { CustomTextField } from './CustomTextField'
import { Box } from '@mui/system'

export const AddressInput = ({ formik }: any) => {
  const onSelect = (address, placeId, suggestion) => {
    geocodeByPlaceId(placeId)
      .then((results) => {
        if (results && results.length > 0) {
          const components = results[0].address_components
          if (components && components.length > 0) {
            const postalCode = components.find((comp) => comp.types.includes('postal_code'))
            const city = components.find(
              (comp) => comp.types.includes('locality') || comp.types.includes('sublocality'),
            )
            const country = components.find((comp) => comp.types.includes('country'))
            const state = components.find((comp) =>
              comp.types.includes('administrative_area_level_1'),
            )

            if (country) {
              formik.setFieldValue('country', country.short_name)
            }

            if (postalCode) {
              formik.setFieldValue('zip', postalCode.long_name)
            }

            if (city) {
              formik.setFieldValue('city', city.long_name)
            }

            if (state) {
              formik.setFieldValue('state', state.short_name)
            }
          }
        }
      })
      .catch((error) => console.error(error))
    formik.setFieldValue('address1', suggestion.formattedSuggestion.mainText)
  }

  const handleChange = (value: string) => {
    formik.setFieldValue('address1', value)
  }

  const searchOptions = {
    types: ['street_number', 'route'],
    componentRestrictions: { country: ['ca', 'us', 'mx'] },
  }

  return (
    <Box sx={{ width: '100%' }} className='location-search'>
      <PlacesAutocomplete
        value={formik.values.address1}
        onChange={handleChange}
        onSelect={onSelect}
        searchOptions={searchOptions}
        className='location-search'
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Box sx={{ position: 'relative', zIndex: 10, backgroundColor: 'white' }}>
            <CustomTextField
              {...getInputProps()}
              sx={{ width: '100%' }}
              aria-describedby='address-popover'
              id='address1'
              name='address1'
              placeholder='Street address or P.O.Box'
              required
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '56px',
                left: '0px',
                right: '0px',
                zIndex: 10,
                backgroundColor: 'white',
              }}
            >
              {(loading || suggestions.length > 0) && (
                <List
                  component='div'
                  aria-label='main mailbox folders'
                  sx={{ width: '100%', zIndex: 10 }}
                >
                  {loading && <ListItem sx={{ width: '100%' }}>Loading...</ListItem>}
                  {suggestions.map((suggestion) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <ListItemButton
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.description}
                      >
                        {suggestion.description}
                      </ListItemButton>
                    )
                  })}
                </List>
              )}
            </Box>
          </Box>
        )}
      </PlacesAutocomplete>
    </Box>
  )
}
