import { Box, Rating, Stack, Typography } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Img from '../../assets/images/bounce.png'
import { BLocation } from '../../types'

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #dedede',
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
  },
  location: {
    fontSize: '12px',
  },
  cardImage: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

interface Props {
  selected?: boolean
  location: BLocation
  onClick: () => void
}

export const ServicerCard = ({ location, selected, onClick }: Props) => {
  const classes = useStyles()
  return (
    <Stack
      direction='row'
      className={classes.container}
      onClick={onClick}
      sx={{ backgroundColor: selected ? 'rgba(27, 161, 250, 0.3)' : 'inherit' }}
    >
      <Box className={classes.cardImage}>
        <img src={Img} alt='boune img' className='bounce-img-card' />
      </Box>
      <Box>
        <Typography className={classes.title}>We fix it phone Repair</Typography>
        <Typography className={classes.location}>{location.address1}</Typography>
        <Typography
          className={classes.location}
        >{`${location.city}, ${location.state} ${location.zip}`}</Typography>
        <Typography className={classes.location}>{location.country}</Typography>
        <Stack direction='row' alignItems='center' alignContent='center' spacing={1}>
          <Typography>4.7</Typography>
          <Rating value={4.7} readOnly />
          <Typography>(90)</Typography>
        </Stack>
      </Box>
    </Stack>
  )
}
