import React from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { capitalize, lowerCase } from 'lodash'

import { FullScreenLoader } from '../../../components'
import { useAppSelector } from '../../../redux/store'
import { useGetUsersQuery, useUpdateUserStatusMutation } from '../../../redux/api/agentApi'
import { useGetActiveUserRoleListQuery } from '../../../redux/api/roleApi'
import { UserTable } from '../../../components/Table/UsersTable'
import { IUpdateStatusRequest, IUser } from '../../../types'

import { AgentUserForm } from './AgentUserForm'

export function AgentUserManagementPage() {
  const user = useAppSelector((state) => state.userState.user)
  const { isLoading, isError, error, data: users, refetch } = useGetUsersQuery()
  const [updateUserStatus, { isLoading: disabling, isSuccess, error: dError, isError: dIsError }] =
    useUpdateUserStatusMutation()
  const { data: userRoleList } = useGetActiveUserRoleListQuery()

  const [isVisible, setIsVisible] = React.useState(false)
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [editableUser, setEditableUser] = React.useState<IUser>()

  const applications = user?.userRoleList
    ?.map((role) => {
      if (lowerCase(role.userRole) === 'admin') {
        return {
          label: capitalize(role.application),
          value: role.applicationId,
        }
      }
    })
    ?.filter((app) => !!app)

  React.useEffect(() => {
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        // eslint-disable-next-line prettier/prettier
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (dIsError) {
      if (Array.isArray((dError as any).data.error)) {
        // eslint-disable-next-line prettier/prettier
        (dError as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((dError as any).data.message, {
          position: 'top-right',
        })
      }
    }

    if (isSuccess) {
      refetch()
    }
  }, [disabling])

  const handleDeactivate = (data: IUpdateStatusRequest) => {
    updateUserStatus(data)
  }

  if (isLoading || !users) {
    return <FullScreenLoader />
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Users
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px' }}
            startIcon={<AddIcon />}
            onClick={() => setIsVisible(true)}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <UserTable
        rows={users}
        searchKey={searchKey}
        disableUser={handleDeactivate}
        editUser={(user) => {
          setIsVisible(true)
          setEditableUser(user)
        }}
      />
      <AgentUserForm
        open={isVisible}
        onClose={() => {
          setEditableUser(undefined)
          setIsVisible(false)
        }}
        refetch={refetch}
        options={userRoleList?.map((userRole) => ({
          label: capitalize(userRole.userRole),
          value: userRole.userRoleId,
        }))}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        applications={applications ?? []}
        user={editableUser}
      />
    </Container>
  )
}
