import React from 'react'
import { Box, Typography, Container, Pagination } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { SearchHeader } from '../../../components/Electrolux/SearchHeader'
import { useSearchSolicitationsQuery } from '../../../redux/api/solicitationApi'
import { SearchResultTable } from '../../../components/Electrolux/SearchResultTable'
import { ISolicitation } from '../../../types'
import { isEmpty } from 'lodash'

interface Props {
  data: ISolicitation[]
  currentPage: number
  setCurrentPage: (value: number) => void
}

const PaginationComponent = ({ data, currentPage, setCurrentPage }: Props) => {
  if (isEmpty(data) || data.length < 7) {
    return null
  }

  const pageCount = Math.ceil(data.length / 6)

  return (
    <Box sx={styles.pagination}>
      <Pagination
        count={pageCount}
        shape='rounded'
        page={currentPage}
        showLastButton={false}
        onChange={(_event, value) => setCurrentPage(value)}
      />
    </Box>
  )
}

export const SearchResult = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const search = useLocation().search
  const { isLoading, data = [] } = useSearchSolicitationsQuery(search)
  const searchParams = new URLSearchParams(search)
  let subTitle = 'By Customer'
  if (searchParams.has('shortCode')) {
    subTitle = 'By Invitation ID'
  } else if (searchParams.has('serial')) {
    subTitle = 'By Product'
  }

  const rows = data.slice((currentPage - 1) * 6, currentPage * 6)

  return (
    <Box>
      <SearchHeader />
      <Box sx={styles.content}>
        <Container maxWidth='xl'>
          <Box sx={styles.header}>
            <Typography sx={{ ...styles.title }}>Search Results</Typography>
            <Typography sx={{ ...styles.title, fontSize: '32px' }}>{subTitle}</Typography>
          </Box>
        </Container>
        <Container maxWidth='xl'>
          <SearchResultTable isLoading={isLoading} data={rows} />
          <PaginationComponent
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </Box>
    </Box>
  )
}

const styles = {
  content: {
    backgroundColor: '#E0E7EA',
    paddingBottom: '30px',
    height: 'calc(100vh - 189px)',
    overflow: 'auto',
  },
  header: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  title: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '42px',
    color: '#222529',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '34px',
  },
}
