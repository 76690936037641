/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useResetPasswordMutation } from '../../../redux/api/authApi'

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const buttonStyle = {
  display: 'block',
  ml: 'auto',
  mr: 'auto',
}

export const ResetPasswordForm = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [resetPassword, { isLoading, isError, error, isSuccess }] = useResetPasswordMutation()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Your password is updated!')
      navigate('/login')
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      resetPassword({
        resetToken: params.token ? params.token : '',
        password: values.password,
      })
    },
  })

  const hasMoreEight = formik.values.password.length > 7
  const hasNumber = /\d/.test(formik.values.password)
  const hasCapital = /(?=.*[A-Z])/.test(formik.values.password)
  const hasSpecial = /(?=.*[-+_!@#$%^&*., ?])/.test(formik.values.password)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant='h6' component='h2' mb={3} align='center'>
        Reset Password
      </Typography>
      <Stack spacing={3}>
        <TextField
          fullWidth
          id='password'
          name='password'
          placeholder='Password'
          type={!showPassword ? 'password' : 'text'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          id='confirm_password'
          name='confirm_password'
          placeholder='Confirm Password'
          type={!showConfirmPassword ? 'password' : 'text'}
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Box
        p={2}
        mt={3}
        bgcolor='rgb(250, 250, 210)'
        borderRadius={2}
        borderColor='rgb(218, 165, 32)'
      >
        <Stack direction='row' spacing={1}>
          {hasMoreEight ? <CheckIcon /> : <CloseIcon />}
          <Typography>Your password must have at least 8 characters.</Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          {hasCapital ? <CheckIcon /> : <CloseIcon />}
          <Typography>Your password must have at least 1 capital letter.</Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          {hasNumber ? <CheckIcon /> : <CloseIcon />}
          <Typography>Your password must have at least 1 number</Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          {hasSpecial ? <CheckIcon /> : <CloseIcon />}
          <Typography>Your password must have at least 1 special character.</Typography>
        </Stack>
      </Box>
      <Button variant='contained' type='submit' sx={{ mt: 3, ...buttonStyle }}>
        Submit
      </Button>
    </form>
  )
}
