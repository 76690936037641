import React from 'react'
// import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Container, Typography } from '@mui/material'
// import SearchIcon from '@mui/icons-material/Search'
import { FullScreenLoader, SolicitationPurchaseIncompleteTable } from '../../components'
import { useGetSolicitationPurchaseIncompleteListQuery } from '../../redux/api/reports/solicitationPurchaseIncompleteApi'

export function SolicitationPurchaseIncompleteReport() {
  const { isLoading, data, refetch } = useGetSolicitationPurchaseIncompleteListQuery()
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [isVisible, setIsVisible] = React.useState(false)

  if (isLoading || !data) {
    return <FullScreenLoader />
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Incomplete Solicitation Purchases
        </Typography>
        {/* <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
        </Stack> */}
      </Stack>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='subtitle1'>
          These are solicitations that have been paid for but an exception occurred when sent to
          Electrolux. Solicitations will be removed from this list once Electrolux fixes the
          exception and we have received their contract number.
        </Typography>
      </Stack>
      <SolicitationPurchaseIncompleteTable rows={data ?? []} searchKey={searchKey} />
    </Container>
  )
}
