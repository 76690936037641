import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  CircularProgress,
  styled,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { IDispatchMessageBrandDetails, IDispatchMessageMaster } from '../../types'
import {
  useCreateDispatchMessageMutation,
  useUpdateDispatchMessageMutation,
} from '../../redux/api/dispatchMessageMasterApi'
import { useAppSelector } from '../../redux/store'
import BrandAutocomplete from './BrandAutocomplete'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '10px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60px',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

interface Props {
  dispatchMessageMaster: IDispatchMessageMaster | undefined
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  brandCode: yup.string().required('Brand name is required'),
  model: yup.string().required('Model name is required'),
  message: yup.string().required('Message is required'),
})

export const DispatchMessageForm = ({ onClose, refetch, open, dispatchMessageMaster }: Props) => {
  const [isLoadingBrandDetails, setIsLoadingBrandDetails] = useState(true)
  const [brandsDetails, setBrandsDetails] = useState<IDispatchMessageBrandDetails[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${process.env.REACT_APP_SA_PHASE1_BASE_API_URL}/standard/variables/getmanufacturers`
        const bearerToken = process.env.REACT_APP_SA_PHASE1_AUTHOURITY

        const res = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: bearerToken || '',
            'Content-Type': 'application/json',
          },
        })

        const data = await res.json()
        setBrandsDetails(data?.data ? data.data : [])
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingBrandDetails(false)
      }
    }
    fetchData()
  }, [])
  const [
    createDispatchMessage,
    { isLoading: cIsLoading, isError: cIsError, error: cError, isSuccess: cIsSuccess },
  ] = useCreateDispatchMessageMutation()
  const [
    updateDispatchMessageMaster,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateDispatchMessageMutation()
  const user = useAppSelector((state) => state.userState.user)
  const formik = useFormik({
    initialValues: {
      brandCode: dispatchMessageMaster?.brandCode ?? '',
      model: dispatchMessageMaster?.model ?? '',
      message: dispatchMessageMaster?.message ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const requestData = {
        brandCode: values.brandCode,
        model: values.model,
        message: values.message,
      }

      if (dispatchMessageMaster?.dispatchMessageId) {
        updateDispatchMessageMaster({
          dispatchMessageId: dispatchMessageMaster.dispatchMessageId,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
          ...requestData,
        })
      } else {
        user &&
          createDispatchMessage({
            application: 'intranet',
            createdBy: `${user.firstName} ${user.lastName}`,
            ...requestData,
          })
      }
    },
  })
  useEffect(() => {
    if (cIsSuccess || uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (cIsError || uIsError) {
      const error = cIsError ? cError : uError
      if (Array.isArray((error as any)?.data?.error)) {
        ;(error as any).data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [cIsLoading, uIsLoading])

  useEffect(() => {
    formik.setValues({
      brandCode: dispatchMessageMaster?.brandCode ?? '',
      model: dispatchMessageMaster?.model ?? '',
      message: dispatchMessageMaster?.message ?? '',
    })
  }, [dispatchMessageMaster])

  useEffect(() => {
    if (!open) {
      formik.resetForm()
    }
  }, [open])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            {dispatchMessageMaster ? 'Edit Dispatch Message' : 'Create Dispatch Message'}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          {isLoadingBrandDetails ? (
            <CircularProgress />
          ) : (
            <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1 }}>
              <Stack spacing={1} direction={'row'}>
                <Item style={{ flexGrow: 1 }}>
                  <BrandAutocomplete mockOptions={brandsDetails} formik={formik} />
                  {formik.touched.brandCode && formik.errors.brandCode && (
                    <Typography variant='body2' color='error'>
                      {formik.errors.brandCode}
                    </Typography>
                  )}
                </Item>
                <Item>
                  <TextField
                    fullWidth
                    id='model'
                    name='model'
                    label='Model'
                    autoComplete='off'
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                  />
                </Item>
              </Stack>
              <Stack spacing={1}>
                <Item>
                  <TextField
                    fullWidth
                    id='message'
                    name='message'
                    label='Dispatch Message'
                    autoComplete='off'
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    multiline
                    rows={4}
                  />
                </Item>
              </Stack>
              <Box sx={{ textAlign: 'right' }}>
                <Item>
                  <Button
                    variant='outlined'
                    size='large'
                    sx={{ mr: 2, borderRadius: '20px' }}
                    color='error'
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    sx={{ borderRadius: '20px' }}
                    size='large'
                    type='submit'
                    disabled={cIsLoading || uIsLoading}
                  >
                    Submit
                    {(cIsLoading || uIsLoading) && <CircularProgress size={20} />}
                  </Button>
                </Item>
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
