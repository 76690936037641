import customOpwFetchBase from './customOpwFetchBase'
import { createApi } from '@reduxjs/toolkit/query/react'
import { BLocationList, GenericResponse, ICreateClaimData } from '../../types'

export const bounceApi = createApi({
  reducerPath: 'bounceApi',
  baseQuery: customOpwFetchBase,
  endpoints: (builder) => ({
    getLocations: builder.query<BLocationList, string>({
      query(data) {
        return {
          url: `sctapi/locations?near=${data}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { data: BLocationList } }) => result.data.data,
    }),
    createClaim: builder.mutation<GenericResponse, ICreateClaimData>({
      query(data) {
        return {
          url: `sctapi/claims`,
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const { useLazyGetLocationsQuery, useCreateClaimMutation } = bounceApi
