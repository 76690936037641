import * as React from 'react'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyIcon from '@mui/icons-material/Key'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { deepOrange } from '@mui/material/colors'
import { useLogoutUserMutation } from '../../redux/api/authApi'
import { ChangePasswordForm } from './ChangePasswordForm'
import { getFirstAlpha } from '../../helpers/helper'
import { IUser } from '../../types'

interface Props {
  user: IUser
}

export function UserAvatar({ user }: Props) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation()
  const [isVisible, setIsVisible] = React.useState(false)

  const fullName = `${user?.firstName} ${user?.lastName}`
  const alias = `${getFirstAlpha(user?.firstName)}${getFirstAlpha(user?.lastName)}`

  React.useEffect(() => {
    if (isSuccess) {
      window.location.href = '/login'
    }
  }, [isLoading])

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    logoutUser()
    handleCloseUserMenu()
  }

  return (
    <Stack direction='row' spacing={1}>
      <Avatar sx={{ bgcolor: deepOrange[500] }}>{alias}</Avatar>
      <Button
        onClick={handleOpenUserMenu}
        sx={{ textDecorationLine: 'none' }}
        endIcon={anchorElUser ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        {fullName}
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {user?.createdBy !== 'Microsoft' && (
          <MenuItem onClick={() => setIsVisible(true)}>
            <KeyIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Change Password</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ mr: 1 }} />
          <Typography textAlign='center'>Log Out</Typography>
        </MenuItem>
      </Menu>
      <ChangePasswordForm open={isVisible} onClose={() => setIsVisible(false)} />
    </Stack>
  )
}
