import React from 'react'
import { styled, TextField, OutlinedInput } from '@mui/material'
import { IMaskInput } from 'react-imask'

export const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#1ba1fa',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#1ba1fa',
    },
  },
})

export const CustomInput = styled(OutlinedInput)({
  '&:hover': {
    borderColor: '#1ba1fa!important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1ba1fa',
  },
  '&.Mui-focused': {
    borderColor: '#1ba1fa',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1ba1fa',
  },
})

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask='(#00) 000-0000'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    )
  },
)
