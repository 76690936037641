import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Typography, Box, LinearProgress } from '@mui/material'
import { ISolicitation } from '../../types'
import { isEmpty } from 'lodash'
import { ExpandableRow } from './ExpandedRow'

interface Props {
  isLoading: boolean
  data?: ISolicitation[]
}

export function SearchResultTable({ data, isLoading }: Props) {
  const renderContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={13}>
            <Box>
              <LinearProgress />
            </Box>
          </TableCell>
        </TableRow>
      )
    }
    if (isEmpty(data)) {
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
            <Box sx={styles.empty}>
              <Typography>
                Agent, please proceed to SAP to create an offer for the consumer
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )
    }

    return data?.map((row, index) => (
      <ExpandableRow key={row.solicitationMasterId} row={row} currentIndex={index} />
    ))
  }
  return (
    <TableContainer component={Paper} sx={{ minHeight: '336px' }}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align='left'>Offer Status</StyledTableCell>
            <StyledTableCell align='left'>Offer Expiration Date</StyledTableCell>
            <StyledTableCell align='left'>Invitation ID</StyledTableCell>
            <StyledTableCell align='left'>Name</StyledTableCell>
            <StyledTableCell align='left'>Address</StyledTableCell>
            <StyledTableCell align='left'>City</StyledTableCell>
            <StyledTableCell align='left'>State</StyledTableCell>
            <StyledTableCell align='left'>Zip Code</StyledTableCell>
            <StyledTableCell align='left'>Model #</StyledTableCell>
            <StyledTableCell align='left'>Serial #</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderContent()}</TableBody>
      </Table>
    </TableContainer>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#040F3E',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '18px',
    fontFamily: 'Poppins',
  },
}))

const styles = {
  empty: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
