import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import { ILoginRequestData, GenericResponse, IResetPasswordRequest } from '../../types'
import { userApi } from './userApi'
import { setToken, reset } from '../slice/authSlice'
import { logout } from '../slice/userSlice'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customOpwFetchBase,
  endpoints: (builder) => ({
    loginUser: builder.mutation<{ access_token: string; status: string }, ILoginRequestData>({
      query(data) {
        return {
          url: 'auth/login',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          await dispatch(setToken(data.access_token))
          await dispatch(userApi.endpoints.getMe.initiate(null))
        } catch (error) {
          console.error(error)
        }
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          credentials: 'include',
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          await dispatch(reset())
          await dispatch(logout())
        } catch (error) {
          console.error(error)
        }
      },
    }),
    verifyEmail: builder.mutation<GenericResponse, string>({
      query(verificationCode) {
        return {
          url: `auth/verifyemail/${verificationCode}`,
          credentials: 'include',
        }
      },
    }),
    forgotPassword: builder.mutation<GenericResponse, { email: string }>({
      query(body) {
        return {
          url: `auth/forgotpassword`,
          method: 'POST',
          credentials: 'include',
          body,
        }
      },
    }),
    resetPassword: builder.mutation<GenericResponse, IResetPasswordRequest>({
      query({ password, resetToken }) {
        return {
          url: `auth/resetPassword`,
          method: 'PATCH',
          body: { password, resetToken },
          credentials: 'include',
        }
      },
    }),
    loginMicrosoft: builder.mutation<
      { access_token: string; status: string },
      { token: string; elxToken: string | null }
    >({
      query(body) {
        return {
          url: `auth/social/microsoft`,
          method: 'POST',
          credentials: 'include',
          body,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          await dispatch(setToken(data.access_token))
          await dispatch(userApi.endpoints.getMe.initiate(null))
        } catch (error) {
          console.error(error)
        }
      },
    }),
  }),
})

export const {
  useLoginUserMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useLoginMicrosoftMutation,
  useLogoutUserMutation,
} = authApi
