/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, TextField, Stack, Typography, Box, Modal } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import {
  useCreateSaleSourceMutation,
  useUpdateSaleSourceMutation,
} from '../../redux/api/saleSourceApi'
import { ISaleSource } from '../../types'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}

interface Props {
  saleSource?: ISaleSource
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  saleSource: yup.string().required('Sale source is required'),
})

export const SaleSourceForm = ({ onClose, refetch, open, saleSource }: Props) => {
  const [createSaleSource, { isLoading, isError, error, isSuccess }] = useCreateSaleSourceMutation()
  const [
    updateSaleSource,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateSaleSourceMutation()

  const formik = useFormik({
    initialValues: {
      saleSource: saleSource?.saleSource ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const requestData = {
        saleSource: values.saleSource,
      }
      if (saleSource?.saleSourceId) {
        updateSaleSource({
          saleSourceId: saleSource?.saleSourceId,
          ...requestData,
        })
      } else {
        createSaleSource({
          ...requestData,
        })
      }
    },
  })

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  React.useEffect(() => {
    if (saleSource) {
      formik.setValues({
        saleSource: saleSource?.saleSource ?? '',
      })
    }
  }, [saleSource])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Sale Source Details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id='saleSource'
                name='saleSource'
                value={formik.values.saleSource}
                placeholder='Sale Source'
                onChange={formik.handleChange}
                error={formik.touched.saleSource && Boolean(formik.errors.saleSource)}
                helperText={formik.touched.saleSource && formik.errors.saleSource}
              />
            </Stack>
            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                color='error'
                onClick={reset}
              >
                Cancel
              </Button>
              <Button variant='contained' sx={{ borderRadius: '20px' }} size='large' type='submit'>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
