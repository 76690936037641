/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Stack, Typography, Button, CircularProgress } from '@mui/material'
import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import { CustomTextField } from './CustomTextField'
import { IEquipment } from '../../types'

const validationSchema = yup.object({
  make: yup.string().max(50),
  model: yup.string().max(30),
  serial: yup.string().max(50),
})

interface Props {
  onSubmit: (values: IEquipment) => void
  setActiveStep: (step: number) => void
  loading: boolean
}

export const EquipmentDetails = ({ onSubmit, setActiveStep, loading }: Props) => {
  const formik = useFormik({
    initialValues: {
      make: '',
      model: '',
      serial: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ alignItems: 'center' }}>
        <MarkAsUnreadOutlinedIcon sx={{ color: '#1ba1fa' }} />
        <Typography variant='h5' component='h5'>
          Equipment
        </Typography>
      </Stack>
      <Stack
        direction='column'
        spacing={2}
        sx={{
          backgroundColor: 'white',
          borderRadius: '6px',
          marginTop: '10px',
          padding: '30px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <CustomTextField
            id='model'
            name='model'
            placeholder='Device Model'
            onChange={formik.handleChange}
            value={formik.values.model}
            error={formik.touched.model && Boolean(formik.errors.model)}
            helperText={formik.touched.model && formik.errors.model}
            sx={{ width: '100%' }}
          />
          <CustomTextField
            id='make'
            name='make'
            placeholder='Device Manufacturer'
            onChange={formik.handleChange}
            value={formik.values.make}
            error={formik.touched.make && Boolean(formik.errors.make)}
            helperText={formik.touched.make && formik.errors.make}
            sx={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <CustomTextField
            id='serial'
            name='serial'
            placeholder='Device Serial Number'
            onChange={formik.handleChange}
            value={formik.values.serial}
            error={formik.touched.serial && Boolean(formik.errors.serial)}
            helperText={formik.touched.serial && formik.errors.serial}
            sx={{ width: '100%' }}
          />
        </Stack>
        <Stack marginTop='30px' direction='row' spacing={2} justifyContent='end'>
          <Button
            variant='outlined'
            sx={{ textTransform: 'none' }}
            onClick={(e) => {
              e.preventDefault()
              setActiveStep(2)
            }}
          >
            Back
          </Button>
          <Button disabled={loading} variant='contained' type='submit' style={{ height: '37px' }}>
            {loading ? <CircularProgress size={20} /> : 'Submit'}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
