import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box } from '@mui/material'
import { useCookies } from 'react-cookie'
import { Navigate, useSearchParams } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { FullScreenLoader } from '../../../components'
import { LoginForm } from './LoginForm'
import { EmailInputForm } from './EmailInputForm'
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { useAppSelector } from '../../../redux/store'
import { useGetApplicationMutation } from '../../../redux/api/applicationApi'
import { electroluxMsalConfig, msalConfig } from '../../../config/msalConfig'

const onpointMsalInstance = new PublicClientApplication(msalConfig)
const electroluxMsalInstance = new PublicClientApplication(electroluxMsalConfig)

export function LoginPage() {
  const [getApplication, { isLoading }] = useGetApplicationMutation()
  const application = useAppSelector((state) => state.applicationState.application)
  const [open, setOpen] = React.useState(false)
  const [cookies] = useCookies(['logged_in'])
  const [searchParams] = useSearchParams()
  const [applicationName, setAppliationName] = useState<string | null>()
  const token = searchParams.get('token')
  const param = searchParams.get('application')

  useEffect(() => {
    if (applicationName) {
      getApplication(applicationName)
    }
  }, [applicationName])

  useEffect(() => {
    let app = param
    if (param) {
      localStorage.setItem('application', param)
    } else {
      app = localStorage.getItem('application')
    }
    setAppliationName(app)
  }, [])

  if (cookies.logged_in) {
    return <Navigate to='/' replace />
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  const handleClose = () => setOpen(false)

  const handleOpen = () => setOpen(true)

  const containerSx = {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    backgroundImage: '',
  }

  const childSx = {
    height: '100%',
    border: 'none',
  }

  let agentLogo

  if (application?.application.toLowerCase() !== 'Intranet'.toLowerCase()) {
    if (application?.backgroundImgPath) {
      containerSx.backgroundImage = `url(${application.backgroundImgPath})`
    }
    if (application?.vendorLogoPath) {
      agentLogo = application?.vendorLogoPath
    }
  }

  let msalInstance = onpointMsalInstance

  if (application?.application === 'Electrolux') {
    msalInstance = electroluxMsalInstance
  }

  const renderForm = (agentLogo?: string) => {
    if (!applicationName) {
      return <EmailInputForm onContinue={(value) => setAppliationName(value)} />
    }
    return <LoginForm clickForgotPassword={handleOpen} agentLogo={agentLogo} token={token} />
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Box>
        <Grid container sx={containerSx}>
          <Grid item xs={12}>
            <Grid sx={childSx} container justifyContent='center' alignItems='center'>
              <Grid item sx={{ width: 450 }}>
                <Paper sx={{ p: 5 }}>{renderForm(agentLogo)}</Paper>
              </Grid>
            </Grid>
          </Grid>
          <ForgotPasswordForm onClose={handleClose} open={open} />
        </Grid>
      </Box>
    </MsalProvider>
  )
}
