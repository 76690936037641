import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IYearPlan, IYearPlans } from '../../types'
import { isEmpty } from 'lodash'

interface IYearPlansState {
  yearDetails: IYearPlans
}

const initialState: IYearPlansState = {
  yearDetails: {},
}

export const yearDetailsSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    addYearDetail: (state, action: PayloadAction<IYearPlan[]>) => {
      if (!isEmpty(action.payload)) {
        state.yearDetails = {
          ...state.yearDetails,
          [action.payload[0].solicitationDetailId]: action.payload,
        }
      }
    },
  },
})

export default yearDetailsSlice.reducer

export const { addYearDetail } = yearDetailsSlice.actions
