import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IUserRole,
  GenericResponse,
  IUpdateRoleStatusRequest,
  IUpdateRoleRequest,
} from '../../types'

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['UserRole'],
  endpoints: (builder) => ({
    getUserRoleList: builder.query<IUserRole[], void>({
      query() {
        return {
          url: 'roles/all',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { userRoleList: IUserRole[] } }) =>
        result.data.userRoleList,
    }),
    getActiveUserRoleList: builder.query<IUserRole[], void>({
      query() {
        return {
          url: 'roles/active',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { userRoleList: IUserRole[] } }) =>
        result.data.userRoleList,
    }),
    createUserRole: builder.mutation<GenericResponse, { userRole: string }>({
      query(data) {
        return {
          url: 'roles/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUserRole: builder.mutation<GenericResponse, IUpdateRoleRequest>({
      query(data) {
        return {
          url: 'roles/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateUserRoleStatus: builder.mutation<GenericResponse, IUpdateRoleStatusRequest>({
      query(data) {
        return {
          url: 'roles/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetUserRoleListQuery,
  useGetActiveUserRoleListQuery,
  useCreateUserRoleMutation,
  useUpdateUserRoleMutation,
  useUpdateUserRoleStatusMutation,
} = roleApi
